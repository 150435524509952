import { Delete } from '@mui/icons-material';
import { Autocomplete, Grid, IconButton, MenuItem, Paper, TextField, FormControlLabel, Checkbox } from '@mui/material'
import { map } from 'lodash';
import React, { useEffect, useState } from 'react'


const NewFilterParameter = ({ index, id, selectedItem, setSelectedItem, deleteFilter, type }) => {

    const filterAttributes = () => {
        let attributes = {}

        if(type === "dataProduct")
            attributes = { isValid: false, name: "NewFilter" + index, description : "", order: (selectedItem.appliedFilterCount)}
        if(type === "bespoke")
            attributes ={ isValid: false, name: "NewFilter" + index, description : "", required : false, order: (selectedItem.appliedFilterCount)}
        return attributes;
    }

    const [parameter, setParameter] = useState(()=>filterAttributes());
    const [columnLoading, setColumnLoading] = useState(true);
    const [newParameterName, setNewParameterName] = useState("NewFilter" + index);
    const [disable, setDisable] = useState(false);

    const columns = () => {
        let existingFilters = type === "dataProduct" ? selectedItem.customParameters : type === "bespoke"  ? selectedItem.customViewsParameters: []
        let filteredCols = selectedItem.columns.filter(column => !existingFilters?.map(x => x.name).includes(column));
        filteredCols = filteredCols.filter(column => !selectedItem.input.parameters?.map(x => x.name).includes(column));
        
        if (newParameterName !== "NewFilter" + index)
            filteredCols.push(newParameterName);

        return filteredCols;
    }

    const handleInputChange = (event, parameterName, newValue, isNewFilterHeader) => {
        const target = event.target;
        const value = target.value;
        const targetName = target.name;

        if (!value && !isNewFilterHeader) return

        setSelectedItem(prevState => {
            let newObj = prevState;

            if(isNewFilterHeader){
                if(newValue){

                    newObj.input.parameters = newObj.input.parameters.map((param) => {
                        if(param.name === parameterName){
                            return {...param, name: newValue, isValid : true}
                        }
                        return param
                    });

                    setParameter(prevState => ({
                        ...prevState, 
                        name: newValue,
                        isValid :true
                     }));
                }
                else{
                        let inValidInput = {};
                        newObj.input.parameters = newObj.input.parameters.map((param) => {
                            if(param.name === parameterName){
                                inValidInput = {...param, name: "NewFilter" + index, isValid : false}
                                return inValidInput
                            }
                            return param
                        });
                        setParameter(inValidInput);
                }
            }
            else{
                newObj.input.parameters = newObj.input.parameters.map((param) => {
                    if(param.name === parameterName){
                        return {...param, [targetName] : value}
                    }
                    return param
                });
                setParameter(prevState => {
                    return {...prevState, [targetName] : value}
                 })
            }

            return newObj
        })

        if(isNewFilterHeader)
            setNewParameterName(newValue ?? "NewFilter" + index);
    }

    const handleCheckBoxChange = (event, parameterName) => {
        const target = event.target;
        const checked = target.checked;
        const targetName = target.name;
        
         setParameter(prevState => {
            return {...prevState, [targetName] : checked}
         });

        setSelectedItem(prevState => {
            let newObj = prevState;
            newObj.input.parameters = newObj.input.parameters.map((param) => {
                if(param.name === parameterName){
                    return {...param, [targetName] : checked}
                }
                return param;
            })
            return newObj;
        })
    }

    const deleteChildFilter = (filterName) => {
        deleteFilter(filterName, id);
        setNewParameterName(null);
    }

    useEffect(() => {
        let currentparameter = selectedItem.input.parameters.filter((param) => {return param.name == newParameterName})
        if(currentparameter.length > 0 && currentparameter[0]?.order !== undefined
            && parameter?.order !== undefined
            && currentparameter[0].order !== parameter.order ){
                setParameter(prevState =>  {
                    let param = prevState;
                    param = {...param, order:currentparameter[0].order}
                    return param
                })
            }
    },[selectedItem?.input.parameters.filter((param) => {return param.name == newParameterName})[0]?.order])

    //initial loading of new filter
    useEffect(() => {
        setDisable(newParameterName === "NewFilter" + index ? true : false );
        if(newParameterName === "NewFilter" + index){
            setSelectedItem(prevState => {
                let newObj = prevState;
                let existingparam = newObj.input.parameters.filter((param) => param.name === newParameterName)
                if(existingparam.length === 0){
                    newObj.input.parameters = [...newObj.input.parameters, parameter]
                }
                return newObj;
            })
        }
    },[newParameterName])

    return (
        <Grid item container xs={2} >
            <Paper variant="outlined" sx={{ padding: '10px', backgroundColor: !parameter.isValid ? 'primary.light':''}}>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        options={columns()}
                        name="newParameterName"
                        renderInput={(params) => <TextField {...params} size="small" name="NewParameterName" label="Parameter Name" />}
                        onChange={(event, newValue) => 
                            handleInputChange(event, newParameterName, newValue, true)}
                        loading={columnLoading}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField name="description" label="Description" size="small" fullWidth 
                        disabled={disable} onChange={(event) => handleInputChange(event, newParameterName, null, false)} />
                </Grid>
                {type === "bespoke" &&
                <Grid item xs={12} >
                    <FormControlLabel label="Required Filter"
                        control={<Checkbox name="required" checked={parameter.required} 
                        disabled={disable} onChange={(event) => handleCheckBoxChange(event, newParameterName)} />} 
                        />
                </Grid>
                }
                <Grid item xs={8}>
                    <TextField name="order" label="Order" size="small" fullWidth value={parameter.order} select
                        disabled={disable} onChange={(event) => handleInputChange(event, newParameterName, null, false)}>
                        {[...Array(selectedItem.appliedFilterCount + 1).keys()].map((item, index) => {
                                return index > 0 && <MenuItem value={item} key={index}>{item}</MenuItem>
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <IconButton onClick={() => deleteChildFilter(newParameterName)}>
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>
            </Paper>
        </Grid>
    )
}

export default React.memo(NewFilterParameter)