import React, { useState, useEffect } from 'react';
import { CircularProgress, Backdrop, Typography, useTheme, Grid } from '@mui/material'
import doRequest from '../../lib/services/apiRequestor';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import ViewListIcon from '@mui/icons-material/ViewList';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate, useLocation } from 'react-router-dom';
import SharedListDialog from '../../lib/components/SharedListDialog';
import RequestStatusAlert from '../../lib/components/RequestStatusAlert';
import DeleteConfirmationDialog from '../../lib/components/DeleteConfirmationDialog';
import CopyListDialog from '../../lib/components/CopyListDialog';

export default function ManageLists({ idToken, drawerWidth, user }) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { searchValue } = state ? state : '';
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [userLists, setUserLists] = useState([]);
    const [searchText, setSearchText] = useState(searchValue);
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [open, setOpen] = useState(false);
    const [openDelete, setDeleteOpen] = useState(false);
    const [data, setData] = useState([]);
    const [creator, setCreator] = useState("");
    const [openCopyListDialog, setOpenCopyListDialog] = useState(false);

    useEffect(() => {
        if (user?.mail) {
            setCreator(user.mail);
            GetUserLists(idToken, user.mail);
        }
        else {
            user = JSON.parse(sessionStorage.getItem("UserDetails"));
            setCreator(user.mail);
            GetUserLists(idToken, user.mail);
        }
    }, [user?.mail])

    const GetUserLists = (idToken, userEmail) => {
        setIsLoading(true);

        let url = `/api/GetUserListsByUser?userEmail=${userEmail}`
        doRequest(url, idToken)
            .then((response) => {
                if (!response.isError) {
                    setUserLists(response.data);
                }
                setIsLoading(false);
            })
            .catch((error) => { JSON.stringify(error) })
    }

    const handleRowClick = (params) => {
        const rowData = { search: searchText, viewOnly: (params.row.role === 'ReadOnly') };
        return navigate(`/manageLists/editList/${encodeURIComponent(params.row.id)}`, { state: rowData });
    }

    const handleEditClick = (id) => () => {
        const editedRow = userLists.find((row) => row.id === id);
        setData(editedRow);
        const rowData = { search: searchText, viewOnly: false };
        return navigate(`/manageLists/editList/${encodeURIComponent(editedRow.id)}`, { state: rowData });
    };

    const handleViewClick = (id) => () => {
        const viewedRow = userLists.find((row) => row.id === id);
        setData(viewedRow);
        const rowData = { search: searchText, viewOnly: true };
        return navigate(`/manageLists/editList/${encodeURIComponent(viewedRow.id)}`, { state: rowData });
    };

    const handleSharedClick = (id) => () => {
        const sharedRow = userLists.find((row) => row.id === id);
        setData(sharedRow);
        setOpen(true);
    };

    const handleDeleteClick = (id) => () => {
        const deleteRow = userLists.find((row) => row.id === id);
        setData(deleteRow);
        setDeleteOpen(true);
    };

    const handleCopyClick = (id) => () => {
        const copyRow = userLists.find((row) => row.id === id);
        setData(copyRow);
        setOpenCopyListDialog(true);
    };

    const onFilterModelChangedCallback = (model) => {
        if (model.quickFilterValues.length > 0) {
            setSearchText(model.quickFilterValues[0]);
        }
        else {
            setSearchText('')
        }
    }

    const updateStausMessage = (message, severity) => {
        if (message != null && severity != null) {
            setStatusMessage(message);
            setSeverity(severity);
        }
    }

    return (
        <div>
            <Backdrop sx={{ color: '#055A60', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2} style={{ width: 'auto', marginTop: "2px", marginLeft: drawerWidth - 15 }} >
                <Grid item xs={12}><Typography variant="h6">Manage Lists</Typography></Grid>
                <Grid item xs={12}>Select one of the List below to edit it.</Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        height: 725,
                        width: 1,
                        '& .super-app-theme--header': {
                            backgroundColor: '#377B7F',
                            color: theme.palette.common.white,
                        },
                        '& .MuiDataGrid-cell:hover': {
                            color: '#377B7F',
                            cursor: 'pointer'
                        },
                    }}>
                        <DataGrid
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            columns={[{
                                field: 'name', headerClassName: 'super-app-theme--header',
                                headerAlign: 'left', headerName: 'List Name', flex: 1, sortable: false
                            },
                            {
                                field: 'description', headerClassName: 'super-app-theme--header',
                                headerAlign: 'left', headerName: 'Description', flex: 1, sortable: false
                            },
                            {
                                field: 'ownedBy', headerClassName: 'super-app-theme--header',
                                headerAlign: 'left', headerName: 'Owned By', flex: 1, sortable: false
                            },
                            {
                                field: 'actions',
                                type: 'actions',
                                headerName: 'Actions',
                                headerClassName: 'super-app-theme--header',
                                headerAlign: 'center',
                                flex: 1,
                                sortable: false,
                                getActions: ({ id }) => {
                                    const row = userLists.find((r) => r.id === id);
                                    if (row.role === 'ReadOnly') {
                                        return [
                                            <GridActionsCellItem
                                                icon={<ViewListIcon />}
                                                label="View"
                                                sx={{
                                                    color: 'primary.main',
                                                    marginLeft: '5px'
                                                }}
                                                onClick={handleViewClick(id)}
                                            />,
                                            <GridActionsCellItem
                                            icon={<ContentCopyIcon />}
                                            label="Copy"
                                            sx={{
                                                color: 'primary.main',
                                                marginLeft: '5px'
                                            }}
                                            onClick={handleCopyClick(id)}
                                        />
                                        ];
                                    }
                                    return [
                                        <GridActionsCellItem
                                            icon={<EditIcon />}
                                            label="Edit"
                                            sx={{
                                                color: 'primary.main',
                                                marginLeft: '5px'
                                            }}
                                            onClick={handleEditClick(id)}
                                        />,
                                        <GridActionsCellItem
                                            icon={<ShareIcon />}
                                            label="Share"
                                            sx={{
                                                color: 'primary.main',
                                                marginLeft: '5px'
                                            }}
                                            onClick={handleSharedClick(id)}
                                        />,
                                        <GridActionsCellItem
                                            icon={<DeleteIcon />}
                                            label="Delete"
                                            sx={{
                                                color: 'primary.main',
                                                marginLeft: '5px'
                                            }}
                                            onClick={handleDeleteClick(id)}
                                        />,
                                        <GridActionsCellItem
                                            icon={<ContentCopyIcon />}
                                            label="Copy"
                                            sx={{
                                                color: 'primary.main',
                                                marginLeft: '5px'
                                            }}
                                            onClick={handleCopyClick(id)}
                                        />,
                                    ];
                                },
                            }]}
                            rows={userLists}
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterValues: [searchText],
                                    },
                                },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                    printOptions: { disableToolbarButton: true }
                                },
                            }}
                            onRowClick={handleRowClick}
                            onFilterModelChange={onFilterModelChangedCallback}
                        />
                    </Box>
                </Grid>
                <SharedListDialog
                    idToken={idToken}
                    open={open}
                    setOpen={setOpen}
                    data={data}
                    creator={creator}
                    updateStausMessage={updateStausMessage} />
                <DeleteConfirmationDialog
                    idToken={idToken}
                    open={openDelete}
                    setOpen={setDeleteOpen}
                    page={"ManageList"}
                    message={"Are you sure, you want to delete the list: " + data.name + "?"}
                    data={data}
                    updateStausMessage={updateStausMessage} />
                <CopyListDialog 
                    idToken = {idToken} 
                    openCopyListDialog = {openCopyListDialog}
                    setOpenCopyListDialog = {setOpenCopyListDialog}
                    data = {data}
                    userName = {user?.mail}
                    setStausMessage = {setStatusMessage} 
                    setSeverity = {setSeverity}
                    openedfrom = {'ManageLists'} />
                <Grid item xs={12}>
                    <RequestStatusAlert message={statusMessage} severity={severity} />
                </Grid>
            </Grid>

        </div>
    );
}