import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { updateUserQuery, draftQuery } from '../services/customQueryService';

export default function OverwriteQueryDialog({idToken, openOverwriteQueryDialog, setOpenOverwriteQueryDialog, selectedQueryObj, setSelectedQueryObj, customParameterValues, setOpen, updateStausMessage, setReloadSelectedQuery, setIsLoading}) {
  const handleClose = () => {
    setOpenOverwriteQueryDialog(false);
  };

  const handleSave = () => {
    setOpenOverwriteQueryDialog(false);
    let query = draftQuery(customParameterValues);
    const newinput = { Id: selectedQueryObj.id, Name: selectedQueryObj.name, Description: selectedQueryObj.description, Query: JSON.stringify(query) }
    updateUserQuery(idToken, newinput, setIsLoading, successCallBack, errorCallBack)
    .then((data) => {
      setSelectedQueryObj(data)
    });
      
  };

  const handleSaveAs = () => {
    setOpenOverwriteQueryDialog(false);
    setOpen(true);
  };

  function successCallBack() {
    setReloadSelectedQuery(true);
    updateStausMessage("Query updated successfully!", "success");
  }
  
  function errorCallBack() {
    updateStausMessage("An error occurred, please retry.", "error");
  }

  return (
    <div>
      <Dialog
        open={openOverwriteQueryDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Query Save / Save as new Query."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to save existing query <b>{selectedQueryObj?.name}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>Save</Button>
          <Button variant="contained" onClick={handleSaveAs} autoFocus>Save As</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}