import { Backdrop, CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ErrorDialog from './ErrorDialog';

const SecurityRoute = ({ routes, path, children }) => {
    const navigate = useNavigate();
    const route = routes?.find(x => x.type === path);
    const [showSecurityError, setShowSecurityError] = useState(!route?.hasAccess);

    function onCloseSecurityMessage() {
        navigate("/")
    }

    return (
        <>
            {routes !== null ?
                !route?.hasAccess ?
                    <ErrorDialog message={"You do not have required access to this page."} open={showSecurityError} setOpen={setShowSecurityError} onClose={onCloseSecurityMessage} />
                    : children
                : <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress />
                </Backdrop>
            }
        </>
    )
}

export default SecurityRoute