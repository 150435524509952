import React, { useEffect, useState } from 'react'
import { Button, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material'
import DataProductPage from '../DataProductPage/App'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import doRequest from '../../lib/services/apiRequestor'
import { DataObject } from '@mui/icons-material'
import QueryParameterFilters from '../../lib/components/QueryParameterFilters'
import { fetchCustomParameters, formatCustomParametersIntoFormData, clearCustomParameterVlaues, fetchAvailableDates } from '../../lib/services/customQueryService'
import DataPreviewCountSelector from '../../lib/components/DataPreviewCountSelector'
import { useParams, useLocation, useNavigate } from "react-router-dom"
import RequestStatusAlert from '../../lib/components/RequestStatusAlert';
import Banner from '../../lib/components/Banner';


const DataProductResult = ({user, idToken, drawerWidth }) => {
    const { dataProductName } = useParams();
    const location = useLocation();
    const data = location.state;
    const [value, setValue] = useState(dataProductName); //selected data product by user
    const navigate = useNavigate()
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [availableDates, setAvailableDates] = useState([]);
    const [getLatest, setGetLatest] = useState(true);
    const [submit, setSubmit] = useState(false);
    const [resubmit, setReSubmit] = useState(0);
    const [dataPreviewRowCount, setDataPreviewRowCount] = useState(1000);
    const [customParameters, setCustomParameters] = useState(null);
    const [toggleCustomParameters, setToggleCustomParameters] = useState(false);
    const [toggleClearFilters, setToggleClearFilters] = useState(false);
    const [customParameterValues, setCustomParameterValues] = useState({});
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [formData, setFormData] = useState({});
    const dateFormat = 'sv'; //ensures dates are formatted as 'YYYY-MM-DD'.
    const [message, setMessage] = useState(null);
    const [className, setClassName] = useState(null);
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        if (toggleCustomParameters === true) {
            formatCustomParametersIntoFormData(customParameterValues, customParameters, setFormData)
        } else {
            setFormData({});
        }
    }, [customParameterValues, toggleCustomParameters])

    useEffect(() => {
        // side hook to fetch available dates
        if (value == null) return
        else {
            fetchAvailableDates(idToken, value, setAvailableDates);
            fetchCustomParameters(idToken, value, setCustomParameters);
        }
    }, [value])

    const handleGetLatest = (event) => {
        setGetLatest(event.target.checked);
    }

    const handleCustomParametersToggle = (event) => {
        setToggleCustomParameters(event.target.checked);
    }

    const disableDates = (date) => {
        return !availableDates.includes(date.toLocaleDateString(dateFormat))
    }

    const toggleDatePicker = (value) => {
        if (value == null | availableDates.length == 0 | getLatest == true) return true
        else if (value != null && availableDates.length > 0 && getLatest == false) return false
    }
    const handleBackClick = (event) => {
        navigate('/explore', { state: { searchValue: data.search } });
    }
    const handleClearFilterClick = (event) => {
        setToggleClearFilters(!toggleClearFilters);
        clearCustomParameterVlaues(customParameterValues, setCustomParameterValues, customParameters);
    }
    const updateStausMessage = (message, severity) => {
        if (message != null && severity != null) {
            setStatusMessage(message);
            setSeverity(severity);
        }
    }

    return (
        <Grid container spacing={2} style={{ width: 'auto', marginTop: "2px", marginLeft: drawerWidth - 15}} >
            <Grid item xs={4} style={{ height: '91.5vh', borderRight: '1px solid #eee', width: '100%', overflow: 'auto' }}>
                <Grid container direction="row" rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} >
                        <Button onClick={handleBackClick}><u>Back to Data Product List</u></Button>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant={'h7'}>Data Product Name:</Typography>
                        <Typography variant={'h6'} sx={{ wordBreak: "break-word" }}>{dataProductName}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() => { setSubmit(true); setReSubmit(resubmit + 1) }}>Run</Button>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={11} xl={4}>
                            <DatePicker
                                disabled={toggleDatePicker(value)}
                                label="From"
                                value={fromDate}
                                onChange={setFromDate}
                                renderInput={(params) => <TextField {...params} />}
                                shouldDisableDate={disableDates}
                                inputFormat="dd/MM/yyyy"
                                mask="__/__/____"
                            />
                        </Grid>
                        <Grid item xs={11} xl={4}>
                            <DatePicker
                                disabled={toggleDatePicker(value)}
                                label="To"
                                value={toDate}
                                onChange={setToDate}
                                renderInput={(params) => <TextField {...params} />}
                                shouldDisableDate={disableDates}
                                inputFormat="dd/MM/yyyy"
                                mask="__/__/____"
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid container item lg={12} xl={4} alignItems="center" >
                        <FormGroup>
                            <FormControlLabel control={<Switch defaultChecked onChange={handleGetLatest} />} label="Get Latest" />
                        </FormGroup>
                    </Grid>
                    <Grid item xl={4} md={6} xs={8}>
                        <DataPreviewCountSelector dataPreviewRowCount={dataPreviewRowCount} setDataPreviewRowCount={setDataPreviewRowCount} />
                    </Grid>
                    <Grid item xs={12}>
                        {customParameters && customParameters.length > 0 && value &&
                            <QueryParameterFilters
                            user={user}
                            dataProductName={value}
                            customParameters={customParameters}
                            handleCustomParametersToggle={handleCustomParametersToggle}
                            setCustomParameterValues={setCustomParameterValues}
                            idToken={idToken}
                            toggleCustomParameters={toggleCustomParameters}
                            customParameterValues={customParameterValues}
                            handleClearFilterClick={handleClearFilterClick}
                            toggleClearFilters={toggleClearFilters}
                            openedFrom={"dataProduct"}
                            updateStausMessage={updateStausMessage}
                            formData={formData}
                            setFormData={setFormData}
                            />
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8} style={{ height: '91.5vh' }}>
                {value && submit
                    ?
                    <>
                        <Grid item xs={12}><Typography variant="h6">Data Preview</Typography></Grid>
                        <Grid item xs={12}>
                            <DataProductPage
                                idToken={idToken}
                                dataProduct={value}
                                fromDate={fromDate}
                                toDate={toDate}
                                getLatest={getLatest}
                                dateFormat={dateFormat}
                                resubmit={resubmit}
                                dataPreviewRowCount={dataPreviewRowCount}
                                formData={formData}
                                setMessage={setMessage}
                                setClassName={setClassName}
                                setShowBanner={setShowBanner}
                            />
                        </Grid>
                    </>
                    :
                    <Grid item sx={{ height: "60vh" }} container alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography variant="h4"><DataObject /> Run a data query</Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12}>
                <RequestStatusAlert message={statusMessage} severity={severity} />
            </Grid>
            <Banner message={message} className={className} showBanner={showBanner} />
        </Grid>
    )
}

export default DataProductResult