import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, TextField, Grid, Typography } from '@mui/material'
import doRequest from '../services/apiRequestor';

export default function SharedListDialog({ idToken, open, setOpen, data, creator, updateStausMessage }) {
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState("");
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);

    useEffect(() => {
        updateStausMessage(statusMessage, severity)
    }, [statusMessage])

    const Validate = () => {
        clearStatusMessage();

        const errors = {};
        if (!email) {
            errors.email = "Select atleast one user to share list.";
        }
        else if (creator.toLowerCase() === email.toLowerCase()) {
            errors.email = "List cannot be shared with self. Please select different user.";
        }
        else if (email) {
            checkIfExist(idToken);
        }
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            setErrors({});
        }
    }

    const handleShareClick = (e) => {
        e.preventDefault();
        Validate();
    };

    const handleCancel = () => {
        setEmail("");
        setErrors({});
        setOpen(false);
    };

    const clearStatusMessage = () => {
        setStatusMessage(null);
        setSeverity(null);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const checkIfExist = (idToken) => {
        clearStatusMessage();
        var getUserShareListUrl = `/api/GetUserSharedListById?userSearchListId=${data.id}&userEmail=${email}`
        doRequest(getUserShareListUrl, idToken)
            .then((response) => {
                if (!response?.isError) {
                    if (Object.keys(response?.data).length > 0) {
                        errors.email = "Already shared. Please select different user."
                        setErrors(errors);
                    }
                    else {
                        saveUserSharedList(idToken, { UserSearchListID: data.id, UserName: email, Role: 'ReadOnly' })
                    }
                }
                else {
                    setStatusMessage("Failed to save filters.")
                    setSeverity("error")
                }
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setStatusMessage(error)
                setSeverity("error")
            })
    };

    const saveUserSharedList = (idToken, formData) => {
        setOpen(false);
        setEmail("");
        clearStatusMessage();
        doRequest(`/api/SaveUserSharedList`, idToken, {
            method: "POST",
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if (!response.isError) {
                    setStatusMessage("List shared successfully.")
                    setSeverity("success")
                }
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setStatusMessage(error)
                setSeverity("error")
            })
    };

    return (
        <div>
            <Dialog fullWidth maxWidth="sm"
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Shared List</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} >
                            <Typography variant={'h7'}>Share a list: <b>{data.name}</b> with below user</Typography>
                        </Grid>
                        <Grid item xl={12}>
                            <form>
                                <div className='padding-top'>
                                    <TextField name="email" className='TextField' onChange={handleEmailChange} value={email} variant="outlined" label="Email" />
                                    {errors.email && <><br /><span className='errorMsg'>{errors.email}</span></>}
                                </div>
                                <div className='action-buttons'>
                                    <Button onClick={handleCancel} color="primary" autoFocus>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleShareClick} type="submit" variant="contained" color="primary" autoFocus>
                                        Share
                                    </Button>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}