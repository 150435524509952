import { Alert, AlertTitle, Grid, Skeleton } from '@mui/material'
import React from 'react'
import DataTable1 from './DataTable1'

const DataPreview = ({ dataPreview, errorMessage, defaultRows }) => {
    return (
        <Grid item xs={12}>
            {dataPreview == null || dataPreview?.length === 0
                ?
                <>
                    <Skeleton variant="rectangular" height={60} sx={{ bgcolor: "#377B7F", borderRadius: "5px 5px 0 0" }} animation="wave" />
                    <Skeleton variant="rectangular" height={350} />
                </>
                :
                errorMessage != null ?
                    <>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {errorMessage}
                        </Alert>
                    </>
                    :
                    dataPreview.data.length === 0 ?
                        <>
                            <Alert severity="info">
                                <AlertTitle>Information</AlertTitle>
                                No data returned for current filter selection, please try different filters.
                            </Alert>
                        </>
                        :
                        <DataTable1 data={dataPreview} defaultRows={defaultRows}/>

            }
        </Grid>
    )
}

export default DataPreview