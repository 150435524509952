import { Alert, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'

const RequestStatusAlert = ({ message, severity }) => {
    const [open, setOpen] = useState(false)
    useEffect(() => {
        setOpen(true)
    }, [message])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    if (severity !== undefined) {
        return (
            message && (
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose} severity={severity} sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
            )   
        )
    }
    return undefined;
}

export default RequestStatusAlert