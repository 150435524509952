import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {CircularProgress, Backdrop, Dialog, DialogTitle, DialogActions, DialogContent, Button, TextField, } from '@mui/material'
import doRequest from '../services/apiRequestor';

export default function CopyListDialog({idToken, openCopyListDialog, setOpenCopyListDialog, data, userName, setStausMessage, setSeverity, openedfrom, setId}) {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() =>{
    if(openCopyListDialog === true && name === ""){
      setName('Copy - ' + data.name)
      setErrors({})
      setStausMessage("")
    }
  },[openCopyListDialog])

  const handleClose = () => {
    setName("")
    setErrors({});
    setStausMessage("")
    setOpenCopyListDialog(false)
  };

  const handleSave = () => {
    setIsLoading(true)
    Validate()
  };

  function successCallBack(input) {

    setIsLoading(false)
    setStausMessage("List copied successfully!");
    setSeverity("success");
    setOpenCopyListDialog(false);
    setName("");

    setTimeout(() => {
      if (openedfrom === 'EditList'){
        setId(input?.id);
        navigate(`/manageLists/editList/${encodeURIComponent(input?.id)}`, {state: { searchValue: '' } });
      }
      if (openedfrom === 'ManageLists'){
        window.location = (`/manageLists`);
      }
    }, 2000);

  }
  
  function errorCallBack() {
    setIsLoading(false)
    setStausMessage("An error occurred, please retry.")
    setSeverity("error");
  }

  const handleListChange=(event)=>
  {
    setName(event.target.value);
  };

  const Validate=()=>
  {
    const errors = {};
    setStausMessage("")

    if(!name){
      errors.name = "List name is required";
      errorCallBack()
    }
    if(name)
    {
      checkListExist(idToken);
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setErrors({});
    }
  }

  const checkListExist = (idToken) => {
    var getUserUrl = `/api/GetUserList/?listName=${name}&userEmail=${userName}`
     doRequest(getUserUrl, idToken)
        .then((response) => {
          if (Object.keys(response?.data).length > 0){
            errors.name ="List name is already exist."
            setErrors(errors);
            errorCallBack()
          }
          else{
            copyList(idToken,{Name:name, Id: data.id, UserName: userName})
          }
        })
        .catch((e) => {
            let error = JSON.parse(e.message);
            console.log(error);
            errorCallBack()
        })
   };

   const copyList = (idToken,formData) => {
    doRequest(`/api/CopyList`, idToken, {
      method: "POST",
      body: JSON.stringify(formData)
    })
      .then((response) => {
        if(!response.isError){
          successCallBack(response.data);
        }
      })
      .catch((e) => {
          let error = JSON.parse(e.message);
          console.log(error);
          errorCallBack()
      })
    };

  return (
    <div>

      <Dialog
        open={openCopyListDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop sx={{ color: '#055A60', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="alert-dialog-title">
          {"Copy list: " + data.name }
        </DialogTitle>
        <DialogContent>
          <div className='padding-top'>
            <TextField name="list" className='TextField' value={name} onChange={handleListChange} variant="outlined" label="List Name" />
            <span className='required'>*</span>
            {errors.name &&<><br/><span className='errorMsg'>{errors.name}</span></>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}