import React, { useEffect, useState } from 'react'
import { Button, Grid, Skeleton, Typography, Alert, AlertTitle, ButtonGroup } from '@mui/material'
import DataTable1 from '../../lib/components/DataTable1'
import doRequest from '../../lib/services/apiRequestor'
import { getSettings } from '../../lib/services/settings'

const UserInteractionPage = ({ idToken, drawerWidth }) => {
    const [dataPreview, setDataPreview] = useState(null);
    const [downloadToggle, setDownloadToggle] = useState(true);
    const [downloadUrl, setDownloadUrl] = useState(`/api/Data/`);
    const [errorMessage, setErrorMessage] = useState(null);

    const fetchDataPreview = (idToken) => {
        setDownloadToggle(true)
        setDownloadUrl(`/api/UserInteractionData/`)
        doRequest('/api/UserInteractions', idToken)
            .then((response) => {
                if (!response.isError) {
                    setDataPreview(response)
                    if (response?.data.length > 0) setDownloadToggle(false)
                }
                else{
                    setErrorMessage(response.data);
                    setDownloadToggle(true)
                    setDataPreview("error")
                }
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setErrorMessage(error.body);
                setDownloadToggle(true)
                setDataPreview("error")
            })
    }

    useEffect(() => {
        setErrorMessage(null)
        setDataPreview(null);
        fetchDataPreview(idToken)
    }, [])

    async function downloadData() {
        const path = downloadUrl;
        const settings = await getSettings();
        const pathConstruct = !path.includes('http') ? `${settings.baseUrl}${path}` : path;
        window.open(pathConstruct)
    }

    return (
        <Grid container spacing={2} style={{ width: 'auto', marginTop: "2px", marginLeft: drawerWidth - 15 }} >
            <Grid item xs={12}><Typography variant="h6">Data Preview</Typography></Grid>
            <Grid container spacing={2} style={{ width: '100%', marginLeft: "5px" }} >
                <Grid item xs></Grid>
                <Grid item xs={4} align="right">
                    <ButtonGroup variant="outlined">
                        <Button variant="contained" onClick={downloadData} disabled={downloadToggle}>Download</Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                    {dataPreview == null || dataPreview?.length == 0
                        ?
                        <>
                            <Skeleton variant="rectangular" height={60} sx={{ bgcolor: "#377B7F", borderRadius: "5px 5px 0 0" }} animation="wave" />
                            <Skeleton variant="rectangular" height={350} />
                        </>
                        :
                        errorMessage != null ?
                            <>
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {errorMessage}
                                </Alert>
                            </>
                            :
                            dataPreview.data.length == 0 ?
                                <>
                                    <Alert severity="info">
                                        <AlertTitle>Information</AlertTitle>
                                        Query has resulted in an empty table. Please try again.
                                    </Alert>
                                </>
                                :
                                <DataTable1 data={dataPreview} />

                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UserInteractionPage