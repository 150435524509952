import { Alert, AlertTitle, Button, ButtonGroup, Grid, Skeleton, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DataTable1 from '../../lib/components/DataTable1'
import doRequest from '../../lib/services/apiRequestor'
import { getSettings } from '../../lib/services/settings'
import { downloadFile } from '../../lib/services/customQueryService'


const DataProductPage = ({ idToken, dataProduct, fromDate, toDate, getLatest, dateFormat, resubmit, dataPreviewRowCount, formData, setMessage, setClassName, setShowBanner }) => {
    const [dataPreview, setDataPreview] = useState(null);
    const [downloadToggle, setDownloadToggle] = useState(true);
    const [downloadUrl, setDownloadUrl] = useState(`/api/Data/`);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const containerName = 'product-reports';

    function convertDateFormat(date) {
        if (date === null) return null
        else return date.toLocaleDateString(dateFormat)
    }

    fromDate = convertDateFormat(fromDate);
    toDate = convertDateFormat(toDate);

    const fetchDataPreview = (idToken, fromDate, toDate) => {
        setDownloadToggle(true)

        var dataPreviewUrl = `/api/DataPreview/`

        dataPreviewUrl += `?DataProductName=${dataProduct}&FromDate=${fromDate}&ToDate=${toDate}&GetLatest=${getLatest}&Rows=${dataPreviewRowCount}`
        setDownloadUrl(`/api/Data/?DataProductName=${dataProduct}&FromDate=${fromDate}&ToDate=${toDate}&GetLatest=${getLatest}`)

        doRequest(dataPreviewUrl, idToken, {
            method: "POST",
            body: JSON.stringify(formData)
        })
            .then((response) => {

                if (!response.isError) {
                    setDataPreview(response)
                    if (response?.data.length > 0) setDownloadToggle(false)
                }
                else {
                    setErrorMessage(response?.data);
                    setDownloadToggle(true)
                    setDataPreview("error")
                }

            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setErrorMessage(error.body);
                setDownloadToggle(true)
                setDataPreview("error")
            })
    }

    useEffect(() => {
        setErrorMessage(null)
        setDataPreview(null);
        fetchDataPreview(idToken, fromDate, toDate)
    }, [dataProduct, resubmit])

    const poll = async function (fn, data, fnCondition, ms) {
        let result = await fn(data);
        console.log(result)
        while (fnCondition(result)) {
            console.log("Report still running, retrying later...")
            await wait(ms);
            result = await fn(data);
        }
        console.log("Report finished running.")
        return result;
    };

    const wait = function (ms = 1000) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    };

    let fetchData = async (data) => {
        let fetchResult;
        await doRequest(`/api/IsDownloadComplete`, idToken, {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((response) => {
                console.log(response.status);
                fetchResult = response;
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setErrorMessage(error.body);
                setLoading(false);
                setShowBanner(false);
            })
        return fetchResult;
    };

    async function downloadData() {
        console.log("Downloading product report...");
        setMessage('Downloading report...Please do not refresh or close the window.')
        setClassName("banner banner-warning");
        setShowBanner(true);
        setErrorMessage(null);
        let validate = response => response.status === 202;

        const path = downloadUrl;
        const settings = await getSettings();
        const pathConstruct = !path.includes('http') ? `${settings.baseUrl}${path}` : path;
        let downloadResponse = null;
        await doRequest(pathConstruct, idToken, {
            method: "POST",
            body: JSON.stringify(formData)
        })
            .then(async (response) => {
                if (!response.isError) {
                    downloadResponse = response;
                }
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setErrorMessage(error.body);
                setLoading(false);
                setShowBanner(false);
            });
        const pollingInterval = 30000;
        let isDownloadReadyResponse = await poll(fetchData, downloadResponse.data, validate, pollingInterval);
        console.log(isDownloadReadyResponse);
        if (isDownloadReadyResponse.ok) {
            const jsonData = isDownloadReadyResponse.data;
            const downloadUri = `/api/DownloadReport/?containerName=${containerName}&&fileName=${jsonData.output}`;
            const downloadPath = !downloadUri.includes('http') ? `${settings.baseUrl}${downloadUri}` : downloadUri;
            if (jsonData.output) {
                processDownloading(downloadPath, jsonData.output);
                setTimeout(() => {
                    setLoading(false);
                    setShowBanner(false);
                }, 5000);
            }
            else {
                setErrorMessage("Missing file to download.");
                setLoading(false);
                setShowBanner(false);
            }
        }
        else {
            setErrorMessage("Error in downloading data");
            setLoading(false);
            setShowBanner(false);
        }
    }

    let processDownloading = async (path, fileName) => {
        const response = await doRequest(path, idToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
        if (!response.isError) {
            var file = { content: response.data, fileName: removeGuidFromFilename(fileName) };
            downloadFile(file);
        } else {
            setErrorMessage('Failed to download file:', response.statusText);
            setLoading(false);
            setShowBanner(false);
        }
    }

    const removeGuidFromFilename = (filename) => {
        let guidPattern = /_[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}\.csv$/;
        return filename.replace(guidPattern, '.csv');
    };

    return (
        <Grid container spacing={2} style={{ width: '100%' }} >
            <Grid item xs={6}>Description</Grid>
            <Grid item xs></Grid>
            <Grid item xs={4} align="right">
                <ButtonGroup variant="outlined">
                    <Button variant="contained"
                        disabled={downloadToggle}
                        onClick={() => {
                            setLoading(true);
                            downloadData()
                        }}>
                        Download {loading === true && (
                            <CircularProgress color="inherit" size={20} sx={{ marginLeft: "10px" }} />
                        )}
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
                {dataPreview == null || dataPreview?.length === 0
                    ?
                    <>
                        <Skeleton variant="rectangular" height={60} sx={{ bgcolor: "#377B7F", borderRadius: "5px 5px 0 0" }} animation="wave" />
                        <Skeleton variant="rectangular" height={350} />
                    </>
                    :
                    errorMessage != null ?
                        <>
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {errorMessage}
                            </Alert>
                        </>
                        :
                        dataPreview.data.length === 0 ?
                            <>
                                <Alert severity="info">
                                    <AlertTitle>Information</AlertTitle>
                                    No data returned for current filter selection, please try different filters.
                                </Alert>
                            </>
                            :
                            <DataTable1 data={dataPreview} />

                }
            </Grid>
        </Grid>
    )
}

export default DataProductPage