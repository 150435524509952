import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, CircularProgress, Button, Dialog, DialogContent, DialogTitle, TextField, Grid} from '@mui/material'
import doRequest from '../services/apiRequestor';
import throttle from 'lodash.throttle';

export default function SaveListDialog({ username, idToken, value, open, setOpen, openedFrom, filterName, dataProductName, setCustomParameterValues, setSelectedListObj, setReloadSelectedList, updateStausMessage }) {
  const [errors, setErrors] = useState({});
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [currentValue, setCurrentValue] = useState([]);
    const [listValue, setListValue] = useState("");
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);

  //init
  useEffect(() =>{
    if( value.length > 0){
      setCurrentValue(value)
      setListValue(value.toString())
    }
  },[value])

    useEffect(() => {
        updateStausMessage(statusMessage, severity)
    }, [statusMessage, severity, updateStausMessage])

  const Validate=()=>
  {
      setStatusMessage(null)
      setSeverity(null)
    const errors = {};
    if(!name){
      errors.name = "Name is required";
    }
    if(value.length === 0){
      errors.list = "List is required";
    }
    if(name)
    {
      checkListExist(idToken);
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setErrors({});
    }
  }

  const handleClose = (e) => {
    e.preventDefault();
    Validate();
  };

  const handleCancel = () => {
    setName("");
    setDescription("");
    setErrors({});
    setOpen(false);
  };
  
  const handleNameChange=(event)=>
  {
    setName(event.target.value);
    
  };
  
  const handletDescriptionchange=(event)=>
  {
    setDescription(event.target.value)
  };

  const handletListChange=(event)=>
  {
    let targetvalue = event.target.value;
    setListValue(targetvalue);
    setCustomParameterValues(currentObj => {
      let newObj = {...currentObj, [filterName]: [targetvalue.split(",")]}
      return newObj
      
    })
  };

  const checkListExist = (idToken) => {
    var getUserUrl = `/api/GetUserList/?listName=${name}&userEmail=${username}`
     doRequest(getUserUrl, idToken)
        .then((response) => {
          if (Object.keys(response?.data).length > 0){
            errors.name ="List name is already exist."
            setErrors(errors);
          }
          else{
            saveUserList(idToken,{Name:name, Username:username, Description:description, Value:openedFrom === "dataproduct"? currentValue : listValue.split(",")})
            setName("")
            setDescription("")
            setOpen(false);
          }
        })
        .catch((e) => {
            let error = JSON.parse(e.message);
            console.log(error);
        })
   };

  const throttled = useRef(throttle((url, newValue) => {
    setLoading(true);
    doRequest(url + `&SearchText=${newValue}`, idToken)
        .then((response) => {
            if (!response.isError) {
                setOptions(response.data);
                setLoading(false);
            }
        })
        .catch((error) => { JSON.stringify(error) })
  }, 2000))

  useEffect(() => {
    if (inputValue !== '') {
      let url = "";
      if(dataProductName && filterName){
        url = `/api/CustomParametersOptions/?DataProductName=${dataProductName}&FieldName=${filterName}`;
        throttled.current(url, inputValue)
      }
    }
  }, [inputValue])

  const saveUserList = (idToken,formData) => {
      doRequest(`/api/SaveUserList`, idToken, {
        method: "POST",
        body: JSON.stringify(formData)
      })
        .then((response) => {
          if(!response.isError){
            setReloadSelectedList(true);
              setSelectedListObj(response.data);
              setStatusMessage("List saved successfully.")
              setSeverity("success")
          }
        })
        .catch((e) => {
            let error = JSON.parse(e.message);
            setStatusMessage(error)
            setSeverity("error")
            console.log(error);
        })
  };

  return (
    <div>
      <Dialog   fullWidth maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Save List</DialogTitle>
        <DialogContent>
        <Grid container>
        <Grid item xl={12}>
        <form>
          <div className='padding-top'>
            <TextField name="name" className='TextField'  onChange={handleNameChange} value={name} variant="outlined" label="Name" /><span className='required'>*</span>
            {errors.name &&<><br/><span className='errorMsg'>{errors.name}</span></>}
          </div>
          {
            openedFrom === "dataProduct" 
            ?
              <Autocomplete className='padding-top'
                multiple
                freeSolo
                disablePortal
                readOnly
                sx={{ width: '80%' }}
                options={options}
                value={currentValue}
                limitTags={3}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={filterName}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
                onChange={(event, newValue) => {
                    setCustomParameterValues(currentObj => {
                        let newObj = {...currentObj, [filterName]: [...newValue]}
                        setCurrentValue(newValue)
                        return newObj
                        
                    })
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
              />
            :
            <div className='padding-top'>
              <TextField name="list" className='TextField' value={listValue} onChange={handletListChange} variant="outlined" label="List" disabled/><span className='required'>*</span>
              {errors.list &&<><br/><span className='errorMsg'>{errors.list}</span></>}
            </div>
          }
          <div className='padding-top'>
              <TextField name="description" multiline rows={4} className='TextField' onChange={handletDescriptionchange} value={description} variant="outlined" label="Description"  />
          </div>
          <div>
            {successMsg}
          </div>
          <div className='action-buttons'>
          <Button onClick={handleCancel} color="primary" autoFocus>
              Cancel
          </Button>
          <Button onClick={handleClose} type="submit" variant="contained" color="primary" autoFocus>
            Save
          </Button>
          </div>
        </form>
        </Grid>
        </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}