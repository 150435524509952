import React, { useState, useEffect } from 'react';
import { Autocomplete, CircularProgress, Button, Dialog, DialogContent, DialogTitle, TextField, Grid } from '@mui/material'
import doRequest from '../services/apiRequestor';
import { applySavedQuery } from '../services/customQueryService';

export default function SearchQueryDialog({ userEmail, idToken, open, setOpen, productName, customParameterValues, selectedQueryObj, setSelectedQueryObj, reloadSelectedQuery, setReloadSelectedQuery }) {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedQuery, setSelectedQuery] = useState(null);


    const handleClose = (e) => {
        e.preventDefault();
        
        let url = `/api/GetUserQuery?userEmail=${userEmail}&queryName=${selectedQuery}&productName=${productName}`
        doRequest(url, idToken)
            .then((response) => {
                setOpen(false);
                if (!response.isError && response.data?.queryKeyValues) {
                    applySavedQuery(customParameterValues, response.data.queryKeyValues);
                    setSelectedQueryObj(response.data);
                }
            })
            .catch((error) => {
                JSON.stringify(error)
                setOpen(false);
            })
    };

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open === true) {
            if(selectedQueryObj === null)
                setSelectedQuery([]);

            setOptions([]);
            getUserQueries(userEmail, productName)
        }
    }, [open])

    useEffect(() => {
        if (reloadSelectedQuery && selectedQueryObj) {
            setReloadSelectedQuery(false);
            setSelectedQuery(selectedQueryObj.name);
        }
    }, [reloadSelectedQuery, selectedQueryObj])

    const getUserQueries = (userEmail, productName) => {
        setLoading(true);

        let url = `/api/GetUserQueries?userEmail=${userEmail}&productName=${productName}`
        doRequest(url, idToken)
            .then((response) => {
                if (!response.isError) {
                    let lists = []
                    response.data.map(item => {
                        return lists.push(item.name)
                    })
                    setOptions(lists);
                }
                setLoading(false);
            })
            .catch((error) => { JSON.stringify(error) })
    }

    return (
        <div>
            <Dialog fullWidth maxWidth="md"
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Select Query</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xl={12}>
                            <form>
                                <Autocomplete className='padding-top'
                                    disablePortal
                                    sx={{ width: '80%' }}
                                    options={options}
                                    value={selectedQuery}
                                    limitTags={3}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Search Query"}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        setSelectedQuery(newValue)
                                    }}
                                />
                                <div className='action-buttons'>
                                    <Button onClick={handleCancel} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleClose} type="submit" variant="contained" color="primary">
                                        OK
                                    </Button>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}