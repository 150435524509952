import React from 'react'
import { Outlet } from 'react-router-dom';

const ExplorePage = () => {

    return (
        <Outlet />
    )
}

export default ExplorePage