import * as MsalAuth from "./authConfig";

export async function callApiWithMsal(accessToken, fetch, url, options) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    if (Object.keys(options).length === 0) {
        options.method = "GET"
    }
    options.credentials = "same-origin"
    options.headers = headers

    return await fetch(url, options);
}

export const msalApiFetch = async (fetch, url, options) => {
    var idToken = await MsalAuth.authToken();
    return await callApiWithMsal(idToken, fetch, url, options);
}