import { graphConfig } from "./authConfig";
import { getSettings } from '../../lib/services/settings'

export const getUser = (response, setGraphData) => {
    callMsGraph(response.accessToken).then(res => {
        setGraphData(res)
        sessionStorage.setItem("UserDetails", JSON.stringify(res));
    });
}

export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => JSON.stringify(error));
}
export async function getEnvironment() {
    var settings = await getSettings();
    var path = `${settings.baseUrl}`;

    if (path.includes('prod')) {
        return 'Prod';
    }
    else if (path.includes('ppd')) {
        return 'PreProd';
    }
    else {
        return 'NonProd';
    }
}
