import styled from '@emotion/styled'
import { FilterList, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material'
import { IconButton, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import DataProductPage from '../../app/DataProductPage/App'
import { dataForColumn, textSearch } from '../services/tableUtils'
import TableFilter from './TableFilter'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      color: "black",
      whiteSpace: "nowrap"
    }
  }));

const isOrderedBy = (orderBy, columnSetting) => {
  return orderBy && columnSetting && orderBy.id === columnSetting.id;
};

const alignmentForType = (type) => {return type === "checkbox" ? 'center' : type === "numeric" ? "right" : 'left'};

function EnhancedTableHead(props) {
  const [filtersOn, setFiltersOn] = useState(false);
  const { data, setColumnSettings, columnSettings, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (columnSetting) => (event) => {
    onRequestSort(event, columnSetting);
  };

  const toggleFilters = (event) => {
    if(filtersOn){
      columnSettings.cells.forEach(columnSetting => {
        if(columnSetting.filter)
          columnSetting.filter.value = null;
      });
      setColumnSettings({...columnSettings});
    }
    setFiltersOn(!filtersOn);
    return data === null? [] : data.map(row => row.name)
  };

  return (
    <TableHead>
      <TableRow>
        {columnSettings.settings.hasGroup? <TableCell className={classes.groupDropdownArrow}/> : null}
        {columnSettings.cells.filter(cell => !cell.grouped).map((columnSetting) => (
          <StyledTableCell
            key={columnSetting.id}
            align={alignmentForType(columnSetting.type)}
            sortDirection={isOrderedBy(orderBy, columnSetting) ? order : false}
            style={columnSetting.style}
          >
            <Box>
              {!columnSetting.sort || columnSetting.sort.enabled 
               ?
                <TableSortLabel
                  active={isOrderedBy(orderBy, columnSetting)}
                  direction={isOrderedBy(orderBy, columnSetting) ? order : 'asc'}
                  onClick={createSortHandler(columnSetting)}
                >
                  {columnSetting.label}
                  {isOrderedBy(orderBy, columnSetting) ? (
                    <span hidden>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
                : columnSetting.label}
              {columnSetting.filter ?
                <Tooltip title="Filter list">
                  <IconButton
                    aria-label="filter list"
                    onClick={e => toggleFilters(e)}
                    disableFocusRipple={true}
                    disableRipple={true}
                    edge="start"
                    size="large">
                    <FilterList />
                  </IconButton>
                </Tooltip>
              : null}
            </Box>
          </StyledTableCell>
        ))}
      </TableRow>
      {filtersOn ?
        <TableRow>
           {columnSettings.settings.hasGroup? <TableCell/> : null}
          {columnSettings.cells.filter(cell => !cell.grouped).map((columnSetting) => (
              <TableCell
                style={columnSetting.style}
                key={columnSetting.id + "-filter-cell"}
                padding={columnSetting.disablePadding ? 'none' : 'normal'}
              >
            {columnSetting.filter && filtersOn ?
                  <TableFilter dataObject={data}  searchFilter={columnSetting.filter.searchFilter} columnSetting={columnSetting} columnSettings={columnSettings} setColumnSettings = {setColumnSettings} key={columnSetting.id + "-filter"}/>
                  :<span/>}
              </TableCell>
          ))}
        </TableRow>
      :null }  
    </TableHead>
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

const DataTable1 = ({data, defaultRows}) => {
  // restarting code here 
  const columns = Object.keys(data.data[0]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRows ? defaultRows : 15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // prev
  const [columnSettings, setColumnSettings] = useState({
    settings:{
      defaultSortColumn: 'name',
      hasGroup: false
    },
    cells:[
      { id: 'Col1', type: "string", disablePadding: false, label: 'Col1', style: { width: "auto" }, 
          filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}
      },
      { id: 'Col2', type: "string", disablePadding: false, label: 'Col2', style: { width: "auto" }, 
          filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}
      },  
      { id: 'Col3', type: "string", disablePadding: false, label: 'Col3', style: { width: "auto" } },
      { id: 'Col4', type: "string", disablePadding: false, label: 'Col4', style: { width: "auto" } }
    ]          
  });

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(columnSettings ? columnSettings.cells.find(cell => cell.id === columnSettings.settings.defaultSortColumn): null);

  const handleRequestSort = (event, columnSetting) => {
    const isAsc = isOrderedBy(orderBy, columnSetting) && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnSetting);
  };

  return (
    <Box sx={{ width: '100%' }}>
    <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
      <Table
      size={"small"}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <StyledTableCell
                key={index}
              >
                {column}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
            >
              {Object.keys(row).map((cell, index) => (
                  <StyledTableCell key={index}>{row[cell]}</StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.data.length}
              rowsPerPageOptions={[5, 10, 15, 20, 30]}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  )
}

export default DataTable1