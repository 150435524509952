import { msalApiFetch } from './msalConfig'
import { getSettings } from '../../lib/services/settings'
    
const doRequest = async (path, accessToken, options={}) => {

	let response;

	var settings = await getSettings();

	const pathConstruct = !path.includes('http') ? `${settings.baseUrl}${path}` : path;

	response = await msalApiFetch(fetch, `${pathConstruct}`, options);

	let responseBody;

	const contentType = response.headers.get("content-type");
	if (contentType && contentType.indexOf("application/json") !== -1)
		responseBody = await response.json();
	else if (contentType && contentType.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") !== -1)
		responseBody = await response.blob();
	else if (contentType && contentType.indexOf("application/octet-stream") !== -1)
		responseBody = await response.blob();
    else
		responseBody = await response.text();

	if (response.ok) {
		return { data: responseBody, isError: false, status: response.status, ok: response.ok};
	} else if (response.status !== 200) {
		return { data: responseBody ?? "An error occurred, please retry.", isError: true, status: response.status, ok: response.ok }
	}
}

export default doRequest