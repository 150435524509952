import { PublicClientApplication } from '@azure/msal-browser'
import { getSettings } from './settings'
import { EventType } from '@azure/msal-browser';

let msalInstance;

export const authConfig = async () => {
    if (msalInstance == null) {
        const settings = await getSettings();
        let clientId = settings.clientId;
        let tenant = settings.tenantId;

        const msalConfig = {
            auth: {
                clientId: clientId,
                authority: "https://login.microsoftonline.com/".concat(tenant),
                redirectUri: window.location.origin,
            },
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: false,
            }
        }

        msalInstance = new PublicClientApplication(msalConfig);
        msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const account = event.payload.account;
                msalInstance.setActiveAccount(account);
            }
        }, error => {
            console.log("addEventCallback failed")
            console.log('error', error);
        });
        await msalInstance.handleRedirectPromise()
            .then(async (tokenResponse) => {
                if (!tokenResponse) {
                    const accounts = msalInstance.getAllAccounts();
                    if (accounts.length === 0) {
                        // No user signed in
                        console.log("IDH2. handleRedirectPromise, loginRedirect");
                        await msalInstance.loginRedirect();
                    }
                } else {
                    msalInstance.setActiveAccount(tokenResponse.account)
                    return msalInstance;
                }
            })
            .catch(err => {
                console.error("Failed to handleRedirectPromise", err);
            });

        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
            console.log("IDH2. No user signed in, loginRedirect");
            await msalInstance.loginRedirect();
        }
    }

    return msalInstance;
}

export const authToken = async () => {
    var msalInstance = await authConfig();
    const activeAccount = msalInstance.getActiveAccount();
    if (!activeAccount) {
        await msalInstance.loginRedirect();
    }
    const request = {
        scopes: ["User.Read"],
        account: activeAccount
    };
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.idToken;
}

export const loginRequest = async () => {
    const settings = await getSettings();
    const appIdUri = "api://".concat(settings.tenantId, "/", settings.servicePrincipal, "/user_impersonation")
    const scopes = {
        scopes: ["User.Read", appIdUri]
    };
    return scopes
}

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
