import { DataObject } from '@mui/icons-material';
import { Alert, AlertTitle, Grid, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomViewItem from '../../lib/components/CustomViewItem';
import CustomViewList from '../../lib/components/CustomViewList';
import DataPreview from '../../lib/components/DataPreview';
import { fetchCustomViews } from '../../lib/services/customQueryService';
import RequestStatusAlert from '../../lib/components/RequestStatusAlert';
import Banner from '../../lib/components/Banner';

const CustomViewPage = ({ idToken, drawerWidth, user }) => {
    const [customViews, setCustomViews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedView, setSelectedView] = useState(null);
    const [dataPreview, setDataPreview] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [dataPreviewLoading, setDataPreviewLoading] = useState(false);
    const [loadingListError, setLoadingListError] = useState(null);
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [message, setMessage] = useState(null);
    const [className, setClassName] = useState(null);
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        setLoadingListError(null)
        fetchCustomViews(idToken, setCustomViews, setLoading, setLoadingListError);
    }, [])

    const updateStausMessage = (message, severity) => {
        if (message != null && severity != null) {
            setStatusMessage(message);
            setSeverity(severity);
        }
    }

    return (
        <Grid container style={{ width: 'auto', marginTop: "5px", marginLeft: drawerWidth - 23 }} >
            <Grid item xs={2.5} style={{ height: '91.5vh', borderRight: '1px solid #eee', width: '100%', overflow: 'auto' }} container direction="row">
                {
                    loading ?
                        <>
                            <Grid item xs={12} sx={{ backgroundColor: "white" }}>
                                <Skeleton variant="rectangular" width={"100%"} height={60} sx={{ marginTop: "2px" }} />
                                <Skeleton variant="rectangular" width={"100%"} height={60} sx={{ marginTop: "2px" }} />
                                <Skeleton variant="rectangular" width={"100%"} height={60} sx={{ marginTop: "2px" }} />
                            </Grid>
                        </>
                        :
                        customViews.length > 0 ?
                            <Grid item xs={12}>
                                <CustomViewList customViews={customViews} idToken={idToken} setSelectedView={setSelectedView} setDataPreview={setDataPreview} />
                            </Grid>
                            :
                            loadingListError != null ?
                                <Grid container item xs={12} sx={{ height: '10vh' }} alignItems="center" justifyContent="center">
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        Loading list of bespoke datasets have failed. Please refresh or contact support if this issue persists.
                                    </Alert>
                                </Grid>
                                :
                                <Grid container item xs={12} sx={{ height: '10vh' }} alignItems="center" justifyContent="center">
                                    <Alert severity="info">
                                        <AlertTitle>Information</AlertTitle>
                                        No bespoke datasets currently enabled.
                                    </Alert>
                                    <Typography></Typography>
                                </Grid>
                }
            </Grid>
            <Grid item xs={9.5} style={{ height: '91.5vh' }}>
                {selectedView &&
                    <CustomViewItem
                        view={selectedView}
                        idToken={idToken}
                        setDataPreview={setDataPreview}
                        setErrorMessage={setErrorMessage}
                        setDataPreviewLoading={setDataPreviewLoading}
                        user={user}
                        updateStausMessage={updateStausMessage}
                        setMessage={setMessage}
                        setClassName={setClassName}
                        setShowBanner={setShowBanner}
                    />
                }
                {dataPreview ?
                    <DataPreview dataPreview={dataPreview} errorMessage={errorMessage} dataPreviewLoading={dataPreviewLoading} defaultRows={10} />
                    :
                    selectedView &&
                    <Grid item sx={{ height: "50vh" }} container alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography variant="h4"><DataObject /> Run a data query</Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12}>
                <RequestStatusAlert message={statusMessage} severity={severity} />
            </Grid>
            <Banner message={message} className={className} showBanner={showBanner} />
        </Grid>
    )
}

export default CustomViewPage