import styled from "@emotion/styled";
import { ExpandMore } from "@mui/icons-material"
import { AccordionDetails, AccordionSummary, FormControlLabel, FormGroup, Grid, Switch, Button, Typography, Tooltip } from "@mui/material"
import MuiAccordion from '@mui/material/Accordion';
import React, { useState, useEffect } from 'react'
import { Save, ClearAll } from '@mui/icons-material';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import QueryParameterFilter from "./QueryParameterFilter";
import SaveQueryDialog from './SaveQueryDialog';
import SearchQueryDialog from './SearchQueryDialog';
import OverwriteQueryDialog from './OverwriteQueryDialog';
import { fetchExploreDefaultFilters } from '../services/customQueryService';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    borderRadius: '5px'
}));

const QueryParameterFilters = ({ user, dataProductName, customParameters, handleCustomParametersToggle, setCustomParameterValues,
    idToken, toggleCustomParameters, customParameterValues, handleClearFilterClick, toggleClearFilters, openedFrom, updateStausMessage,
    formData, setFormData }) => {

    const [open, setOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openOverwriteQueryDialog, setOpenOverwriteQueryDialog] = useState(false);
    const [selectedQueryObj, setSelectedQueryObj] = useState(null);
    const [reloadSelectedQuery, setReloadSelectedQuery] = useState(false);

    useEffect(() => {
        if (customParameters && customParameters.length > 0) {
            fetchExploreDefaultFilters(idToken, dataProductName, user, customParameters, customParameterValues, setCustomParameterValues);
        }
    }, [dataProductName])

    const handleClickOpen = (popupName) => {
        switch (popupName) {
            case 'SaveQueryDialog':
                if (selectedQueryObj !== null && selectedQueryObj.id && selectedQueryObj.id !== "") {
                    setOpenOverwriteQueryDialog(true);
                }
                else {
                    setOpen(true);
                }
                return
            case 'SearchQueryDialog':
                setOpenSearch(true);
                return
            default:
                return
        }
    };

    const handleClickClearAll = () => {
        setSelectedQueryObj(null);
        handleClearFilterClick();
    };

    return (
        <Accordion
            sx={{ width: '95%' }}>
            <AccordionSummary
                expandIcon={<ExpandMore />}>
                <Typography>Additional Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                    container direction="row"
                    rowSpacing={2}>
                    <Grid
                        item xs={5}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch onChange={handleCustomParametersToggle} />}
                                label="Apply filters" />
                        </FormGroup>
                    </Grid>
                    <Grid
                        item xs={7}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip
                            title="Save Query">
                            <span>
                                <Button
                                    variant="contained"
                                    disabled={!toggleCustomParameters}
                                    onClick={() => handleClickOpen("SaveQueryDialog")}>
                                    <Save />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip
                            title="Search Query">
                            <span>
                                <Button
                                    variant="contained"
                                    disabled={!toggleCustomParameters}
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => handleClickOpen("SearchQueryDialog")}>
                                    <SavedSearchIcon />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip
                            title="Clear Filters">
                            <span>
                                <Button
                                    variant="outlined"
                                    disabled={!toggleCustomParameters}
                                    style={{ marginLeft: "10px" }}
                                    onClick={handleClickClearAll}>
                                    <ClearAll />
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                    {customParameters.map((item, key) => {
                        return <QueryParameterFilter
                            user={user}
                            key={key}
                            item={item}
                            setCustomParameterValues={setCustomParameterValues}
                            idToken={idToken}
                            dataProductName={dataProductName}
                            toggleCustomParameters={toggleCustomParameters}
                            customParameterValues={customParameterValues}
                            toggleClearFilters={toggleClearFilters}
                            openedFrom={openedFrom}
                            customParameters={customParameters}
                            formData={formData}
                            setFormData={setFormData}
                            updateStausMessage={updateStausMessage}
                        />
                    })}
                </Grid>
                <SaveQueryDialog
                    username={user.mail}
                    idToken={idToken}
                    open={open}
                    setOpen={setOpen}
                    productName={dataProductName}
                    productType={"DataProduct"}
                    customParameterValues={customParameterValues}
                    updateStausMessage={updateStausMessage}
                    setSelectedQueryObj={setSelectedQueryObj}
                    setReloadSelectedQuery={setReloadSelectedQuery} />
                <SearchQueryDialog
                    userEmail={user.mail}
                    idToken={idToken}
                    open={openSearch}
                    setOpen={setOpenSearch}
                    productName={dataProductName}
                    customParameterValues={customParameterValues}
                    selectedQueryObj={selectedQueryObj}
                    setSelectedQueryObj={setSelectedQueryObj}
                    reloadSelectedQuery={reloadSelectedQuery}
                    setReloadSelectedQuery={setReloadSelectedQuery} />
                <OverwriteQueryDialog
                    idToken={idToken}
                    openOverwriteQueryDialog={openOverwriteQueryDialog}
                    setOpenOverwriteQueryDialog={setOpenOverwriteQueryDialog}
                    selectedQueryObj={selectedQueryObj}
                    setSelectedQueryObj={setSelectedQueryObj}
                    customParameterValues={customParameterValues}
                    setOpen={setOpen}
                    updateStausMessage={updateStausMessage}
                    setReloadSelectedQuery={setReloadSelectedQuery}
                    setIsLoading={null} />
            </AccordionDetails>
        </Accordion>
    )
}

export default QueryParameterFilters