import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

const DataPreviewCountSelector = ({dataPreviewRowCount,setDataPreviewRowCount}) => {
    return (
        <FormControl fullWidth size="small" >
            <InputLabel>Data Preview Rows</InputLabel>
            <Select
                value={dataPreviewRowCount}
                label="Data Preview Rows"
                onChange={(e) => setDataPreviewRowCount(e.target.value)}
                autoWidth
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={1000}>1,000</MenuItem>
                <MenuItem value={5000}>5,000</MenuItem>
            </Select>
        </FormControl>
    )
}

export default DataPreviewCountSelector