import { Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React, { useState } from 'react'

const ItemList = ({ Items, setSelectedItem, setErrorMessage, setShowBanner }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const handleListItemClick = (event, index, item) => {
        setSelectedIndex(index);
        console.log("Selected item", index)
        setSelectedItem(item);
        setErrorMessage(null);
        setShowBanner(false);
    };
    return (
        <List>
            {Items.map((item, index) =>
                <>
                    <ListItem key={index} disablePadding>
                        <ListItemButton selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index, item)}>
                            <ListItemText>{item.displayName}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                </>
            )}
        </List>
    )
}

export default ItemList