import { ThemeProvider } from '@emotion/react';
import { Container, createTheme, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import NavigationBar from './lib/components/NavigationBar';
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { getEnvironment, getUser } from './lib/services/userService';
import { loginRequest } from './lib/services/authConfig';
import NavigationDrawer from './lib/components/NavigationDrawer';
import HomePage from './app/HomePage/HomePage';
import ExplorePage from './app/ExplorePage/App';
import fetchGlobalSettings from './lib/services/globalSettings';
import SecurityRoute from './lib/components/SecurityRoute';
import CustomViewPage from './app/CustomViewPage/CustomViewPage';
import UserInteractionPage from './app/UserInteractionPage/UserInteractionPage';
import DataProductResult from './app/ExplorePage/DataProductResult';
import DataProduct from './app/ExplorePage/DataProduct';
import UnauthorizedPage from './app/UnauthorizedPage/UnauthorizedPage';
import AdminPage from './app/AdminPage/AdminPage';
import CustomReportsPage from './app/ReportsPage/CustomReportsPage';
import ManageLists from './app/ManageListsPage/ManageLists';
import EditList from './lib/components/EditList';
import ManageQueries from './lib/components/ManageQueries';
import EditQuery from './lib/components/EditQuery';

const theme = createTheme({
    palette: {
        primary: {
            main: "#055a60",
            contrastText: '#eee',
            light: "#EBF2F2",
            lightGreen: "#A5D7DA"
        },
        secondary: {
            main: "#377B7F",
        },
        custom: {
            customGreen: "#7DBA61",
            customOrange: "#EE5A33",
            customBlue: "#029AB4"
        }
    }
})

const getRoutes = (globalSettings) => {
    let site = [
        {
            type: "Home",
            roles: globalSettings.pages.find(page => page.type === "Home").roles,
            hasAccess: globalSettings.pages.find(page => page.type === "Home").roles.some(role => role.hasRole),
            path: "/",
            name: "Home",
            showInMenu: true,
            bannerSize: "Small",
            breadcrumb: [
                { path: "/", label: "Home" }
            ]
        },
        {
            type: "Admin",
            roles: globalSettings.pages.find(page => page.type === "Admin").roles,
            hasAccess: globalSettings.pages.find(page => page.type === "Admin").roles.some(role => role.hasRole),
            path: "/Admin",
            name: "Admin",
            showInMenu: false,
            bannerSize: "Small",
            breadcrumb: [
                { path: "/Admin", label: "Admin" }
            ]
        },
        {
            type: "Explore",
            roles: globalSettings.pages.find(page => page.type === "Explore").roles,
            hasAccess: globalSettings.pages.find(page => page.type === "Explore").roles.some(role => role.hasRole),
            path: "/Explore",
            name: "Explore",
            showInMenu: false,
            bannerSize: "Small",
            breadcrumb: [
                { path: "/Explore", label: "Explore" }
            ]
        },
        {
            type: "Bespoke",
            roles: globalSettings.pages.find(page => page.type === "Bespoke").roles,
            hasAccess: globalSettings.pages.find(page => page.type === "Bespoke").roles.some(role => role.hasRole),
            path: "/Bespoke",
            name: "Bespoke",
            showInMenu: false,
            bannerSize: "Small",
            breadcrumb: [
                { path: "/Bespoke", label: "Bespoke" }
            ]
        },
        {
            type: "Reports",
            roles: globalSettings.pages.find(page => page.type === "Reports").roles,
            hasAccess: globalSettings.pages.find(page => page.type === "Reports").roles.some(role => role.hasRole),
            path: "/Reports",
            name: "Reports",
            showInMenu: false,
            bannerSize: "Small",
            breadcrumb: [
                { path: "/Reports", label: "Reports" }
            ]
        },
        {
            type: "UserInteraction",
            roles: globalSettings.pages.find(page => page.type === "UserInteraction").roles,
            hasAccess: globalSettings.pages.find(page => page.type === "UserInteraction").roles.some(role => role.hasRole),
            path: "/UserInteraction",
            name: "UserInteraction",
            showInMenu: false,
            bannerSize: "Small",
            breadcrumb: [
                { path: "/UserInteraction", label: "UserInteraction" }
            ]
        },
        {
            type: "ManageLists",
            path: "/ManageLists",
            name: "Manage Lists",
            showInMenu: false,
            bannerSize: "Small",
            breadcrumb: [
                { path: "/ManageLists", label: "Manage Lists" }
            ]
        },
    ]
    return site;
}


function App() {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [idToken, setToken] = useState(null);
    const [roles, setRoles] = useState(null);
    const [login, setLogin] = useState({});
    const [globalSettings, setGlobalSettings] = useState(null);
    const [environment, setEnvironment] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    let routes = globalSettings && !globalSettings.isError ? getRoutes(globalSettings.data) : null;

    const [open, setOpen] = useState(true)
    const [drawerWidth, setDrawerWidth] = useState(220);

    if (!isAuthenticated) {
        instance.loginRedirect(loginRequest)
    }

    useEffect(() => {
        loginRequest().then((response) => {
            setLogin(response)
            const request = {
                ...login,
                account: accounts[0]
            }
            instance.acquireTokenSilent(request).then((response) => {
                setToken(response.idToken);
                setRoles(response.idTokenClaims.roles);
                getUser(response, setGraphData);
                fetchGlobalSettings(response.idToken, setGlobalSettings);
                getEnvironment().then((response) => { setEnvironment(response) })
            }).catch((e) => {
                instance.acquireTokenRedirect(request).then((response) => {
                    setToken(response.idToken);
                    setRoles(response.idTokenClaims.roles);
                    getUser(response, setGraphData);
                    fetchGlobalSettings(response.idToken, setGlobalSettings);
                    getEnvironment().then((response) => { setEnvironment(response) })
                });
            });
        })
    }, [])

    const handleDrawerOpen = () => {
        setDrawerWidth(220);
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerWidth(50);
        setOpen(false);
    };


    useEffect(() => {
        if (globalSettings?.isError) {
            setErrorMessage("(Error: 400) There has been an error loading configuration. Please refresh the page (CTRL + F5). If the problem persists please contact support.");
            setShowErrorMessage(true);
        } else {
        }
    }, [globalSettings])

    return (
        <>
            <AuthenticatedTemplate>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <NavigationBar user={graphData} />
                        <Toolbar />
                        {roles && (<NavigationDrawer drawerWidth={drawerWidth} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} routes={routes} />)}
                        <Container maxWidth={false}>
                            {idToken && (
                                <Routes>
                                    {roles ?
                                        <>
                                            <Route exact path="/" element={<HomePage idToken={idToken} drawerWidth={drawerWidth} environment={environment} />} />
                                            <Route exact path="/explore" element={
                                                <SecurityRoute routes={routes} path="Explore">
                                                    <ExplorePage />
                                                </SecurityRoute>}>
                                                <Route exact path="/explore" element={<DataProduct idToken={idToken} drawerWidth={drawerWidth} />} />
                                                <Route exact path="/explore/data-products/:dataProductName" element={<DataProductResult idToken={idToken} drawerWidth={drawerWidth} user={graphData} />} />
                                            </Route>
                                            <Route exact path="/bespoke" element={
                                                <SecurityRoute routes={routes} path="Bespoke">
                                                    <CustomViewPage idToken={idToken} drawerWidth={drawerWidth} user={graphData} />
                                                </SecurityRoute>
                                            } />
                                            <Route exact path="/reports" element={
                                                <SecurityRoute routes={routes} path="Reports">
                                                    <CustomReportsPage idToken={idToken} drawerWidth={drawerWidth} user={graphData} />
                                                </SecurityRoute>}>
                                            </Route>
                                            <Route exact path="/userinteraction" element={
                                                <SecurityRoute routes={routes} path="UserInteraction">
                                                    <UserInteractionPage idToken={idToken} drawerWidth={drawerWidth} />
                                                </SecurityRoute>
                                            } />
                                            <Route exact path="/admin" element={
                                                <SecurityRoute routes={routes} path="Admin">
                                                    <AdminPage idToken={idToken} drawerWidth={drawerWidth} />
                                                </SecurityRoute>
                                            } />
                                            <Route exact path="/manageLists" element={
                                                    <ManageLists idToken={idToken} drawerWidth={drawerWidth} user={graphData} />
                                            } />
                                            <Route exact path="/manageLists/editList/:listId" element={<EditList idToken={idToken} drawerWidth={drawerWidth} user={graphData} />} />
                                            <Route exact path="/manageQueries" element={
                                                <ManageQueries idToken={idToken} drawerWidth={drawerWidth} user={graphData} />
                                            }>
                                            </Route>
                                            <Route exact path="/manageQueries/editQuery/:queryId" element={<EditQuery idToken={idToken} drawerWidth={drawerWidth} user={graphData} />} />
                                        </>
                                        :
                                        <Route exact path="*" element={<UnauthorizedPage environment={environment} />} />}
                                </Routes>
                            )}
                        </Container>
                    </BrowserRouter>
                </ThemeProvider>
            </AuthenticatedTemplate>
        </>
    );
}

export default App;
