import { Delete } from '@mui/icons-material';
import { Grid, IconButton, MenuItem, Paper, TextField, Typography, FormControlLabel, Checkbox } from '@mui/material'
import React, {useState, useEffect} from 'react'


const FilterParameter = ({ index, parameterName, selectedItem, setSelectedItem, deleteFilter, type}) => {

const currentParameter = (parameterName) => {
    var param = {};
    if(type === "dataProduct"){
        param = selectedItem.customParameters.filter((item) => item.name === parameterName)[0]
    }
    if(type === "bespoke"){
        param = selectedItem.customViewsParameters.filter((item) => item.name === parameterName)[0]
    }
    return param;
}

const [parameter, setParameter] = useState(()=>currentParameter(parameterName));

const handleInputChange = (event, paramName) => {
    const target = event.target;
    const value = target.value;
    const targetName = target.name;

    if (!value) return

    setSelectedItem(prevState =>{
        let selected = prevState;
        let existingFilter = selected.input.parameters.filter((item) => item.name === paramName)
        if(existingFilter.length === 0){
            selected.input.parameters = [...selected.input.parameters, {name: paramName}]
        }
        
        selected.input.parameters = selected.input.parameters.map((param) => {
            if(param.name === paramName){
                return {...param, [targetName] : value}
            }
            return param;
        })

        return selected;
    })

    setParameter(prevState => {
        return {...prevState, [targetName] : value}
     })
}

const handleCheckBoxChange = (event, paramName) => {      
    const target = event.target;
    const checked = target.checked;
    const targetName = target.name;

    setSelectedItem(prevState =>{
        let selected = prevState;

        let existingFilter = selected.input.parameters.filter((item) => item.name === paramName)
        if(existingFilter.length === 0){
            selected.input.parameters = [...selected.input.parameters, {name: paramName}]
        }

        selected.input.parameters = selected.input.parameters.map((param) => {
            if(param.name === paramName){
                return {...param, [targetName] : checked}
            }
            return param;
        })

        return selected;
    })

    setParameter(prevState => {
        return {...prevState, [targetName] : checked}
     })
}

useEffect(() => {
    let currentparameter = selectedItem.input.parameters.filter((param) => {return param.name == parameterName})
    if(currentparameter.length > 0 && currentparameter[0]?.order !== undefined
        && parameter?.order !== undefined
        && currentparameter[0].order !== parameter.order ){
            setParameter(prevState =>  {
                let param = prevState;
                param = {...param, order:currentparameter[0].order}
                return param
            })
        }
},[selectedItem?.input.parameters.filter((param) => {return param.name == parameterName})[0]?.order])

return (
        <Grid item container xs={2} >
            <Paper variant="outlined" style={{ padding: '10px' }}>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ padding: '10px 0 0 10px' }}>{parameter?.name}</Typography>
                </Grid>             
                <Grid item xs={12}>
                    <TextField name="description" label="Description" size="small" defaultValue={parameter?.description} fullWidth 
                        onChange={(event) => handleInputChange(event, parameter?.name)} />
                </Grid>
                { type === "bespoke" &&
                <Grid item xs={12}>
                    <FormControlLabel label="Required Filter"
                        control={<Checkbox name="required" checked={parameter?.required} 
                        onChange={(event) => handleCheckBoxChange(event, parameter?.name)} />} 
                        />
                </Grid>
                }
                <Grid item xs={8}>
                    <TextField name="order" label="Order" size="small" fullWidth value={parameter?.order} select
                        onChange={(event) => handleInputChange(event, parameter?.name)}>
                        {[...Array(selectedItem.appliedFilterCount + 1).keys()].map((item, index) => {
                            return index > 0 && <MenuItem value={item} key={index}>{item}</MenuItem>
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <IconButton onClick={() => deleteFilter(parameter?.name)}>
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>
            </Paper>
        </Grid>
    )
}

export default React.memo(FilterParameter)