import { AdminPanelSettings, Explore, Summarize, Home, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Schema, People } from '@mui/icons-material'
import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { styled } from '@mui/system';
import MuiDrawer from '@mui/material/Drawer';



const NavigationDrawer = ({ drawerWidth, handleDrawerOpen, handleDrawerClose, open, routes }) => {
    const { pathname } = useLocation();

    function renderAdminButton(routes) {
        let canAccess = routes?.find(x => x.type == "Admin").hasAccess;
        if (canAccess) {
            return (
                <Link to='/admin' style={{ textDecoration: "none", color: "#333" }}>
                    <ListItem disablePadding selected={"/admin" === pathname}>
                        <ListItemButton>
                            <ListItemIcon>
                                <AdminPanelSettings color={setIconColor("/admin")} />
                            </ListItemIcon>
                            <ListItemText primary="Admin" />
                        </ListItemButton>
                    </ListItem>
                </Link>
            )
        }
    }

    function renderUserInteractionButton(routes) {
        let canAccess = routes?.find(x => x.type == "UserInteraction").hasAccess;
        if (canAccess) {
            return (
                <Link to='/userinteraction' style={{ textDecoration: "none", color: "#333" }}>
                    <ListItem disablePadding selected={"/userinteraction" === pathname}>
                        <ListItemButton>
                            <ListItemIcon>
                                <People color={setIconColor("/userinteraction")} />
                            </ListItemIcon>
                            <ListItemText primary="User Interactions" />
                        </ListItemButton>
                    </ListItem>
                </Link>
            )
        }
    }

    function setIconColor(route) {
        return (pathname === route) ? "primary" : "default";
    }

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(3)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(7)} + 1px)`,
        },
    });

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    return (
        <Drawer
            variant="permanent"
            open={open}
        >
            <Toolbar />
            <Box >
                <List>
                    <Link to='/' style={{ textDecoration: "none", color: "#333" }}>
                        <ListItem disablePadding selected={"/" === pathname}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Home color={setIconColor("/")} />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link to='/explore' style={{ textDecoration: "none", color: "#333" }}>
                        <ListItem disablePadding selected={"/explore" === pathname}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Explore color={setIconColor("/explore")} />
                                </ListItemIcon>
                                <ListItemText primary="Explore" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link to='/bespoke' style={{ textDecoration: "none", color: "#333" }}>
                        <ListItem disablePadding selected={"/bespoke" === pathname}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Schema color={setIconColor("/bespoke")} />
                                </ListItemIcon>
                                <ListItemText primary="Bespoke" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link to='/reports' style={{ textDecoration: "none", color: "#333" }}>
                        <ListItem disablePadding selected={"/reports" === pathname}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Summarize color={setIconColor("/reports")} />
                                </ListItemIcon>
                                <ListItemText primary="Reports" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    { renderUserInteractionButton(routes) }
                    {renderAdminButton(routes)}
                </List>
                <List style={{ position: "fixed", bottom: 0, left: 10 }}>
                    <IconButton onClick={() => {
                        open ? handleDrawerClose() : handleDrawerOpen()
                    }}>
                        {open ? <KeyboardDoubleArrowLeft />
                            : <KeyboardDoubleArrowRight />
                        }
                    </IconButton>
                </List>
            </Box>
        </Drawer>
    )
}

export default NavigationDrawer