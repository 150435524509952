import React, { useState, useEffect } from 'react';
import { CircularProgress, Backdrop, Button, Typography, TextField, Grid, Skeleton } from '@mui/material'
import doRequest from '../services/apiRequestor';
import RequestStatusAlert from './RequestStatusAlert';
import CustomViewParameter from './CustomViewParameter';
import QueryParameterFilter from './QueryParameterFilter';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { updateUserQuery, draftQuery } from '../services/customQueryService';
import { regxCsvIgnoreWrappedInDoubleQuotes } from '../services/constants';

export default function EditQuery({idToken, drawerWidth, user}) {
  const { queryId } = useParams();
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate()
  const [view, setView] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [errors, setErrors] = useState({});
  const [customParameterValues, setCustomParameterValues] = useState({});
  const [customParameterUrl, setCustomParameterUrl] = useState(null);
  const [id, setId] = useState(queryId);
  const [name, setName] = useState("");
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");
  const [description, setDescription] = useState("");

  const getUserQuery = (idToken, id) => {
    setIsLoading(true);
    let url = `/api/GetUserQueryById?id=${id}`
    doRequest(url, idToken)
        .then((response) => {
            if (!response.isError) {
              setName(response.data.name);
              setProductName(response.data.productName);
              setProductType(response.data.productType);
              setDescription(response.data.description);
              setCustomParameterValues(formatData(response.data?.queryKeyValues));
            }
            setIsLoading(false);
        })
        .catch((error) => { JSON.stringify(error) })
  }

  const getDataProduct = (idToken, dataProductName) => {
    setIsLoading(true);
    let url = `/api/DataProduct?DataProductName=${dataProductName}`
    doRequest(url, idToken)
        .then((response) => {
            if (!response.isError) {
              setView(response.data)
            }
            setIsLoading(false);
        })
        .catch((error) => { JSON.stringify(error) })
  }
  
  const getCustomView = (idToken, customViewName) => {
    setIsLoading(true);
    let url = `/api/CustomViewByName?CustomViewName=${customViewName}`
    doRequest(url, idToken)
        .then((response) => {
            if (!response.isError) {
              setView(response.data)
            }
            setIsLoading(false);
        })
        .catch((error) => { JSON.stringify(error) })
  }

  function formatData(obj) {
    var propNames = Object.getOwnPropertyNames(obj);
    for (var i = 0; i < propNames.length; i++) {
      var propName = propNames[i];
      if (obj[propName] && obj[propName].length > 0) {
        obj[propName] = obj[propName].split(regxCsvIgnoreWrappedInDoubleQuotes);
      }
    }
    return obj;
  }

  useEffect(() => {
      getUserQuery(idToken, id)
  }, [id])

  useEffect(() => {
    if(productType === "DataProduct"){
      getDataProduct(idToken, productName)
    }
    else if(productType === "BespokeView"){
      getCustomView(idToken, productName)
    }
  }, [productType])

  useEffect(() => {
    if((productType === "DataProduct" && view?.customParameters?.length === 0) || (productType === "BespokeView" && view?.customViewParameters?.length === 0)){
      setIsLoading(true);
    }
    else{
      setIsLoading(false);
    }
  }, [view?.customViewsParameters, view?.customParameters])

  const handleNameChange=(event)=>
  {
    setName(event.target.value)
  };

  const handleDescriptionChange=(event)=>
  {
    setDescription(event.target.value)
  };

  const handleClear = () => {
    if(productType === "DataProduct"){
      clearParameterValues(view?.customParameters, customParameterValues)
    }
    else if(productType === "BespokeView"){
      clearParameterValues(view?.customViewsParameters, customParameterValues)
    }
  };

  const clearParameterValues = (parameters, customParameterValues) => {

    parameters.map(parameter => {

      if(parameter.parameterType.toString().toLowerCase() === "datetime"){
        setCustomParameterValues(currentObj => {
          let newObj = { ...currentObj, [parameter.name]: null }
          return newObj
        })
      }
      else{
        setCustomParameterValues(currentObj => {
          let newObj = { ...currentObj, [parameter.name]: [] }
          return newObj
        })
      }

    })
    return customParameterValues;
  }

  const handleUpdate = (e) => {
    e.preventDefault();

    setStatusMessage(null)
    setSeverity(null)

    let query = draftQuery(customParameterValues)

    const errors = {};
    if (!name) {
        errors.name = "Name is required.";
    }
    else if (Object.keys(query).length === 0) {
        errors.name = "Query is empty, Please try with valid filters values.";
    }
    else if (name) {
        checkIfExist(idToken, query);
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
        setErrors({});
    }
  };

  const checkIfExist = (idToken, query) => {
    setIsLoading(true);
    let userEmail = user.mail

    var getUserUrl = `/api/GetUserQueries?userEmail=${userEmail}&productName=${productName}&queryName=${name}`
    doRequest(getUserUrl, idToken)
        .then((response) => {
            if (!response?.isError) {
              let matchingExists = response?.data.filter(item => item.id !== id)
              if (matchingExists && matchingExists.length > 0) {
                  errors.name = "Query name is already exists."
                  setErrors(errors);
                  setIsLoading(false);
              }
              else {
                  setIsLoading(false);
                  updateUserQuery(idToken, { Id: id, Name: name, Description: description, Query: JSON.stringify(query) }, setIsLoading, successCallBack, errorCallBack)
              }
            }
            else {
                updateStausMessage("Failed to update query.", "error");
                setIsLoading(false);
            }
        })
        .catch((e) => {
            let error = JSON.parse(e.message);
            updateStausMessage(error, "error");
            setIsLoading(false);
        })
  };

  const updateStausMessage = (message, severity) => {
    setStatusMessage(message);
    setSeverity(severity);
  }

  function successCallBack() {
    updateStausMessage("Query updated successfully!", "success");
  }
  
  function errorCallBack() {
    updateStausMessage("An error occurred, please retry.", "error");
  }

  const handleDelete = () => {
    if(id){
      setIsLoading(true);

      let url = `/api/DeleteUserQuery?UserQueryId=${id}`
      doRequest(url, idToken, {
        method: "DELETE"
      })
      .then((response) => {
          if (!response.isError) {
            setStatusMessage("Query deleted successfully!")
            setSeverity("success")
            
            setTimeout(() => {
              navigate('/manageQueries', { state: { searchValue: data.search } });
            }, 2000); 
          }
          else { 
            setStatusMessage("An error occurred, please retry.")
            setSeverity("error")
          }
          setIsLoading(false);
      })
      .catch((error) => { JSON.stringify(error) })
    }
  };

  const handleBackClick = (event) => {
    navigate('/manageQueries', { state: { searchValue: data.search } });
  }

  return (
    <div>
      <Backdrop sx={{ color: '#055A60', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2} style={{ width: 'auto', marginTop: "2px", marginLeft: drawerWidth-15 }}>
        <Grid item xs={12}>
          <RequestStatusAlert message={statusMessage} severity={severity} />
        </Grid>
        <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider'}}>
          <form>
            <Grid item container spacing={2}>
              <Grid item xs={12} >
                <Button onClick={handleBackClick}><u>Back to Manage Queries</u></Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Update Query</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField name="name" className='TextField-medium' size="small" onChange={handleNameChange} value={name} variant="outlined" label="Query name" />
                {errors.name && <><br /><span className='errorMsg'>{errors.name}</span></>}
              </Grid>
              <Grid item xs={12}>
                <TextField name="productName" disabled className='TextField-medium' size="small" value={productName} variant="outlined" label="Product / View name" />
              </Grid>
              <Grid item xs={12}>
                <TextField name="description" multiline rows={2} className='TextField-medium' size="small" onChange={handleDescriptionChange} value={description} variant="outlined" label="Description" />
              </Grid>
              <Grid item xs={6}>
                {
                  view?.customViewsParameters?.map(parameter => {
                    if(!(parameter.parameterType === "DateTime" && (parameter.name === "ASOF_DATE_FROM" || parameter.name === "ASOF_DATE_TO" || parameter.name === "ASOF_DATE"))){
                      
                      return <CustomViewParameter 
                          key={parameter.name}
                          user={user}
                          idToken={idToken}
                          parameter={parameter}
                          view={view}
                          setCustomParameterValues={setCustomParameterValues}
                          customParameterValues={customParameterValues}
                          triggerClearFilters={false}
                          isManageQuery={true}
                          productType={productType}
                          availableDates={[]}
                      />
                    }
                  })
                }
                {
                  view?.customParameters?.map((item, key) => {
                    if(!(item.parameterType.toString().toLowerCase() === "DateTime" && (item.name === "ASOF_DATE_FROM" || item.name === "ASOF_DATE_TO" || item.name === "ASOF_DATE"))){

                      return <div key={item.name} style={{ marginBottom: "10px"}}>
                                <QueryParameterFilter
                                  user={user}
                                  item={item}
                                  setCustomParameterValues={setCustomParameterValues}
                                  idToken={idToken}
                                  dataProductName={view.dataProductName}
                                  customParameterUrl={customParameterUrl}
                                  toggleCustomParameters={true}
                                  customParameterValues={customParameterValues}
                                  toggleClearFilters={false}
                                  setCustomParameterUrl={setCustomParameterUrl}
                                  openedFrom={"dataProduct"}
                                  isManageQuery={true}
                                />
                                </div>
                    }
                  })
                }
              </Grid>
              <Grid item xs={12}>
                <div className='padding-top-bottom'>
                  <Button onClick={handleClear} style={{marginRight:"10px"}} color="primary" variant="outlined">
                    Clear
                  </Button>
                  <Button onClick={handleUpdate} style={{marginRight:"10px"}} variant="contained" color="primary">
                    Update
                  </Button>
                  <Button onClick={handleDelete} style={{marginRight:"10px"}} variant="contained" color="primary">
                    Delete Query
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>
        </Grid>
    </div>
  );
}
