import React from 'react'
import { ThemeProvider } from '@emotion/react';
import { createTheme, Toolbar, AppBar, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system'

const theme = createTheme({
    palette: {
        primary: {
            main: "#055a60",
            contrastText: '#eee',
            light: "#EBF2F2"
        },
        secondary: {
            main: "#377B7F",
        },
        custom: {
            customGreen: "#7DBA61",
            customOrange: "#EE5A33",
            customBlue: "#029AB4"
        }
    }
})
const UnauthorizedPage = ({ environment }) => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={6}>
                                <Box>
                                    <Typography sx={{ color: 'primary.contrastText' }}>
                                        M&G Investment Data Hub
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
            <div className="Top-Margin-100px">
                <h1 className="Text-Align">
                    Access Denied.
                    <br />
                    You are not authorized to access this application.
                </h1>
                <h3 className="Text-Align">
                    ( ARC role needs to be raised for access: "<b>Corp - InvestmentDataHub - {environment}</b>" )
                </h3>
            </div>
        </>
    )
}

export default UnauthorizedPage