import doRequest from "./apiRequestor"
import { regxCsvIgnoreWrappedInDoubleQuotes } from './constants';

export const fetchCustomParameters = (idToken, value, setCustomParameters) => {
    doRequest(`/api/CustomParameters/?DataProductName=${value}`, idToken)
        .then((response) => {
            if (!response.isError) {
                response.data ? setCustomParameters(response.data) : setCustomParameters(null)
            }
            else {
                setCustomParameters(null)
            }
        })
        .catch((error) => {
            setCustomParameters(null)
            JSON.stringify(error)
        })
}

export const formatCustomParametersIntoUrl = (customParameterValues, setCustomParameterUrl) => {
    if (customParameterValues === undefined) return
    else {
        var formData = {};
        var st = ``
        for (let i = 0; i < Object.keys(customParameterValues).length; i++) {
            var paramName = Object.keys(customParameterValues)[i]
            var selectedVariables = customParameterValues[paramName]?.toString();
            if (selectedVariables !== undefined && selectedVariables !== "" && selectedVariables !== 'null') {
                formData[paramName] = `${encodeURIComponent(selectedVariables)}`
                st += `&${paramName}=${encodeURIComponent(selectedVariables)}`
            }
        }
        setCustomParameterUrl(formData)
        return st
    }
}

export const formatCustomParametersIntoFormData = (customParameterValues, customViewsParameters, setFormData) => {
    if (customParameterValues === undefined) return
    else {
        if (customViewsParameters && customViewsParameters.length > 0) {
            var formData = {};
            for (let i = 0; i < customViewsParameters.length; i++) {
                var paramName = customViewsParameters[i].name;
                var selectedVariables = customParameterValues[paramName]?.toString();
                if (selectedVariables !== undefined && selectedVariables !== "" && selectedVariables !== 'null') {
                    formData[paramName] = `${selectedVariables}`
                }
            }
            setFormData(formData);
        }
    }
}

export const clearCustomParameterVlaues = (customParameterValues, setCustomParameterValues, customParameters) => {
    if (customParameterValues === undefined) return
    else {
        for (let i = 0; i < Object.keys(customParameterValues).length; i++) {
            var paramName = Object.keys(customParameterValues)[i]
            var selectedVariables = customParameterValues[paramName]?.toString();
            if (selectedVariables !== undefined && selectedVariables !== "" && selectedVariables !== 'null') {
                var parameterType = customParameters.filter((param) => { return param.name === paramName })[0]?.parameterType;

                if (parameterType.toLowerCase() === "datetime") {
                    customParameterValues[paramName] = null;
                } else {
                    customParameterValues[paramName] = [];
                }
            }
        }
        setCustomParameterValues(customParameterValues);
    }
}

export const applySavedQuery = (customParameterValues, mapObject) => {
    if (customParameterValues === undefined) return
    else {
        for (let i = 0; i < Object.keys(customParameterValues).length; i++) {
            var paramName = Object.keys(customParameterValues)[i];
            customParameterValues[paramName] = paramName.toLowerCase().includes("date") ? null : [];
            for (let j = 0; j < Object.keys(mapObject).length; j++) {
                var mapParamName = Object.keys(mapObject)[j];
                if (!(mapParamName.toLowerCase().includes("date")) && paramName === mapParamName) {
                    customParameterValues[paramName] = mapObject[paramName].split(regxCsvIgnoreWrappedInDoubleQuotes);
                }
            }
        }
    }
}

export const formatCustomParamForFilterIntoUrl = (customParameterValues, fieldName) => {
    if (customParameterValues === undefined) return
    else {
        var st = ``
        for (let i = 0; i < Object.keys(customParameterValues).length; i++) {
            var paramName = Object.keys(customParameterValues)[i]
            var selectedVariables = customParameterValues[paramName]?.toString();
            if (selectedVariables !== undefined && selectedVariables !== "" && paramName !== fieldName) {
                st += `&${paramName}=${encodeURIComponent(selectedVariables)}`
            }
        }
        return st
    }
}

export const fetchCustomViews = (idToken, setCustomViews, setLoading, setErrorMessage) => {
    doRequest('/api/CustomViews', idToken)
        .then((response) => {
            if (!response.isError) {
                setCustomViews(response.data.filter(view => view.enabled === "true"))
                setLoading(false)
            }
            else {
                setLoading(false);
                setErrorMessage(response.data);
            }
        })
        .catch((e) => {
            let error = JSON.parse(e.message);
            setLoading(false);
            setErrorMessage(error.body);
        })
}

export const fetchCustomViewsParameters = (idToken, customViewName, setViewParameters) => [
    doRequest(`/api/CustomViewsParameters/?customViewName=${customViewName}`)
        .then((response) => {
            if (!response.isError) {
                setViewParameters(response.data)
            }
        })
        .catch((error) => { JSON.stringify(error) })
]

export const fetchExploreDefaultFilters = (idToken, dataProductName, user, customParameters, customParameterValues, setCustomParameterValues) => {
    doRequest(`/api/GetDefaultDataProductFilters/?DataProductName=${dataProductName}&UserEmailAddress=${user.mail}`, idToken)
        .then((response) => {
            if (!response.isError && customParameters && customParameters.length > 0) {
                for (var i = 0; i < customParameters.length; i++) {
                    var parameter = customParameters[i];
                    if (parameter.parameterType === "StringArray") {
                        if (response.data[parameter.name]) {
                            var arrResponse = (response.data[parameter.name]).split(regxCsvIgnoreWrappedInDoubleQuotes);
                            customParameterValues[parameter.name] = arrResponse;
                        }
                        else
                            customParameterValues[parameter.name] = [];
                    }
                    else
                        customParameterValues[parameter.name] = null;
                }
                setCustomParameterValues(customParameterValues);
            }
        })
        .catch((error) => { JSON.stringify(error) })
}

export const fetchBespokeDefaultFilters = (idToken, view, user, customParameterValues, setCustomParameterValues) => {
    doRequest(`/api/GetDefaultDataProductFilters/?DataProductName=${view.name}&UserEmailAddress=${user.mail}`, idToken)
        .then((response) => {
            if (!response.isError && view.customViewsParameters && view.customViewsParameters.length > 0) {
                for (let i = 0; i < view.customViewsParameters.length; i++) {
                    var parameter = view.customViewsParameters[i];
                    if (parameter.parameterType === "StringArray") {
                        if (response.data[parameter.name]) {
                            var arrResponse = (response.data[parameter.name]).split(regxCsvIgnoreWrappedInDoubleQuotes);
                            customParameterValues[parameter.name] = arrResponse;
                        }
                        else
                            customParameterValues[parameter.name] = [];
                    }
                    else
                        customParameterValues[parameter.name] = null;
                }
                setCustomParameterValues(customParameterValues);
            }
        })
        .catch((error) => { JSON.stringify(error) })
}

export const downloadFile = (file) => {
    const url = window.URL.createObjectURL(new Blob([file.content]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export const updateUserList = async (idToken, input, setLoading, successCallBack, errorCallBack) => {
    if (setLoading !== null) setLoading(true);
    var data = null;
    await doRequest(`/api/PutUserSearchList/?UserSearchListId=${input.Id}`, idToken, {
        method: "PUT",
        body: JSON.stringify(input)
    })
        .then((response) => {
            if (!response.isError) {
                successCallBack()
                data = response.data;
            } else {
                errorCallBack()
                data = null;
            }
            if (setLoading !== null) setLoading(false);
        })
        .catch((e) => {
            errorCallBack();
            data = null;
            if (setLoading !== null) setLoading(false);
        });

    return data;
}

export const deleteUserList = async (idToken, id, setLoading, deleteListSuccessCallBack, deleteListErrorCallBack) => {
    if (setLoading !== null) setLoading(true);
    var data = null;
    let url = `/api/DeleteUserSearchList?UserSearchListId=${id}`
    doRequest(url, idToken, {
        method: "DELETE"
    })
        .then((response) => {
            if (!response.isError) {
                deleteListSuccessCallBack();
                data = response.data;
            }
            else {
                deleteListErrorCallBack();
                data = null;
            }
            if (setLoading !== null) setLoading(false);
        })
        .catch((e) => {
            deleteListErrorCallBack();
            data = null;
            if (setLoading !== null) setLoading(false);
        })
    return data;
}

export const updateUserQuery = async (idToken, input, setLoading, successCallBack, errorCallBack) => {
    if (setLoading !== null) setLoading(true);
    var data = null;
    await doRequest(`/api/PutUserQuery/?UserQueryId=${input.Id}`, idToken, {
        method: "PUT",
        body: JSON.stringify(input)
    })
        .then((response) => {
            if (!response.isError) {
                successCallBack();
                data = response.data;
                if (setLoading !== null) setLoading(false);
            }
            else {
                errorCallBack();
                data = null;
                if (setLoading !== null) setLoading(false);
            }
        })
        .catch((e) => {
            errorCallBack();
            data = null;
            if (setLoading !== null) setLoading(false);
        })

    return data;
}

export const draftQuery = (values) => {
    let query = JSON.parse(JSON.stringify(values));

    var propNames = Object.getOwnPropertyNames(query);
    for (var i = 0; i < propNames.length; i++) {
        var propName = propNames[i];
        if (query[propName] === undefined || query[propName] === null || query[propName].length === 0) {
            delete query[propName];
        }
    }

    Object.keys(query).map(key => {
        let val = query[key];

        if (Array.isArray(val)) {
            query[key] = val.toString()
        }
    })

    return query;
};

export const fetchAvailableDates = (idToken, dataProductsName, setAvailableDates) => {
    const dateFormat = 'sv'; //ensures dates are formatted as 'YYYY-MM-DD'.
    doRequest(`/api/CustomAvailableDates/?DataProductsName=${dataProductsName}`, idToken)
        .then((response) => {
            if (!response.isError) {
                setAvailableDates(response.data.map((obj) => { return new Date(obj.createdDate).toLocaleDateString(dateFormat) }));
            }
        })
        .catch((error) => { JSON.stringify(error) })
}

