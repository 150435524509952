import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteUserList } from '../services/customQueryService';
export default function DeleteConfirmationDialog({ idToken, open, setOpen, page, message, data, updateStausMessage }) {
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        updateStausMessage(statusMessage, severity)
    }, [statusMessage])

    const clearStatusMessage = () => {
        setStatusMessage(null);
        setSeverity(null);
    }

    const handleDelete = () => {
        clearStatusMessage();
        setOpen(false);
        switch (page) {
            case 'ManageList':
                {
                    if (data.id) {
                        deleteUserList(idToken, data.id, setIsLoading, deleteListSuccessCallBack, deleteListErrorCallBack);
                    }
                    return;
                }
            default:
                return;
        }
    };
    function deleteListSuccessCallBack() {
        setStatusMessage("List deleted successfully.")
        setSeverity("success")

        setTimeout(() => {
            window.location = '/manageLists';
            //navigate('/manageLists', { state: { searchValue: "" } });
        }, 2000);
    }
    function deleteListErrorCallBack() {
        setStatusMessage("An error occurred, please retry.")
        setSeverity("error")
    }

    const handleCancel = () => {
        clearStatusMessage();
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} variant="contained" color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}