import { Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React, { useState } from 'react'

const CustomViewList = ({ customViews, setSelectedView, setDataPreview }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const handleListItemClick = (event, index, view) => {
        setSelectedIndex(index);
        setSelectedView(view);
        setDataPreview(null);
    };
    return (
        <List>
            {customViews.map((view, index) =>
                <>
                    <ListItem key={index} disablePadding>
                        <ListItemButton selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index, view)}>
                            <ListItemText>{view.displayName}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                </>
            )}
        </List>
    )
}

export default CustomViewList