import React, { useEffect, useState } from 'react'
import { Grid, useTheme, Typography } from '@mui/material'
import doRequest from '../../lib/services/apiRequestor'
import { Box } from '@mui/system';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate, useLocation } from 'react-router-dom';

const DataProduct = ({ idToken, drawerWidth }) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { searchValue } = state ? state : '';
    const theme = useTheme();
    const [dataProducts, setDataProducts] = useState([]);
    const [searchText, setSearchText] = useState(searchValue);

    useEffect(() => {
        fetchDataProducts(idToken)
    }, []);

    const fetchDataProducts = (idToken) => {
        doRequest('/api/DataProducts', idToken)
            .then((response) => {
                if (!response.isError) {
                    setDataProducts(response.data.filter(view => view.enabled === "true").map(function (row, i) {
                        return { dataProductName: row.dataProductName, dataProductView: row.dataProductView, id: i, asOfDate: row.asOfDate ?? "No data available" }
                    }))
                }
            })
            .catch((error) => { JSON.stringify(error) })
    }

    const handleRowClick = (params) => {
        const data = { search: searchText, viewName: params.row.dataProductView };
        navigate(`/explore/data-products/${encodeURIComponent(params.row.dataProductName)}`, { state: data });
    }
    const onFilterModelChangedCallback = (model) => {
        if (model.quickFilterValues.length > 0) {
            setSearchText(model.quickFilterValues[0]);
        }
        else {
            setSearchText('')
        }
    }

    return (
        <Grid container spacing={2} style={{ width: 'auto', marginTop: "2px", marginLeft: drawerWidth - 15 }} >
            <Grid item xs={12}><Typography variant="h6">Data Product List</Typography></Grid>
            <Grid item xs={12}>Select one of the Data Products below to explore the data within it.</Grid>
            <Grid item xs={12}>
                <Box sx={{
                    height: 725,
                    width: 1,
                    '& .super-app-theme--header': {
                        backgroundColor: '#377B7F',
                        color: theme.palette.common.white,
                    },
                    '& .MuiDataGrid-cell:hover': {
                        color: '#377B7F',
                        cursor: 'pointer'
                    },
                }}>
                    <DataGrid
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        columns={[{
                            field: 'dataProductName', headerClassName: 'super-app-theme--header',
                            headerAlign: 'center', headerName: 'Data Product Name', flex: 2, sortable: false
                        },
                        {
                            field: 'asOfDate', headerClassName: 'super-app-theme--header',
                            headerAlign: 'center', headerName: 'As Of Date', flex: 1, sortable: false
                        }]}
                        rows={dataProducts}
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterValues: [searchText],
                                },
                            },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                printOptions: { disableToolbarButton: true }
                            },
                        }}
                        onRowClick={handleRowClick}
                        onFilterModelChange={onFilterModelChangedCallback}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default DataProduct