import React, { useState, useEffect } from 'react';
import { CircularProgress, Backdrop, Typography, useTheme, Grid} from '@mui/material'
import doRequest from '../services/apiRequestor';
import { Box } from '@mui/system';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate, useLocation } from 'react-router-dom';

export default function ManageQueries({idToken, drawerWidth, user}) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { searchValue } = state ? state : '';
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [userQueries, setUserQueries] = useState([]);
  const [searchText, setSearchText] = useState(searchValue);

  useEffect(() => {
    if(user){
      let userEmail = user.mail
      GetUserQueries(idToken, userEmail)
    }
  }, [user, idToken])

  const GetUserQueries = (idToken, userEmail) => {
    setIsLoading(true);

    let url = `/api/GetUserQueries?userEmail=${userEmail}`
    doRequest(url, idToken)
        .then((response) => {
            if (!response.isError) {
              setUserQueries(response.data);
              
            }
            setIsLoading(false);
        })
        .catch((error) => { JSON.stringify(error) })
  }

  const handleRowClick = (params) => {
    return navigate(`/manageQueries/editQuery/${encodeURIComponent(params.row.id)}`, { state: { search: searchText }});
  }

  const onFilterModelChangedCallback = (model) => {
      if (model.quickFilterValues.length > 0) {
          setSearchText(model.quickFilterValues[0]);
      }
      else {
          setSearchText('')
      }
  }

  return (
    <div>
      <Backdrop sx={{ color: '#055A60', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2} style={{ width: 'auto', marginTop: "2px", marginLeft: drawerWidth - 15 }} >
            <Grid item xs={12}><Typography variant="h6">Manage Queries</Typography></Grid>
            <Grid item xs={12}>Select one of the query below to edit it.</Grid>
            <Grid item xs={12}>
                <Box sx={{
                    height: 725,
                    width: 1,
                    '& .super-app-theme--header': {
                        backgroundColor: '#377B7F',
                        color: theme.palette.common.white,
                    },
                    '& .MuiDataGrid-cell:hover': {
                        color: '#377B7F',
                        cursor: 'pointer'
                    },
                }}>
                    <DataGrid
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        columns={[{
                            field: 'name', headerClassName: 'super-app-theme--header',
                            headerAlign: 'left', headerName: 'Query Name', flex: 1, sortable: false
                        },
                        {
                          field: 'productName',  headerClassName: 'super-app-theme--header', minWidth: 650,
                            headerAlign: 'left', headerName: 'Product Name', sortable: false
                        },
                        {
                          field: 'description', headerClassName: 'super-app-theme--header',
                          headerAlign: 'left', headerName: 'Description', flex: 1, sortable: false
                        },
                        {
                            field: 'lastModifiedAt', headerClassName: 'super-app-theme--header', maxWidth : 250, align:"center",
                            headerAlign: 'center', headerName: 'Last Modified At', flex: 1, sortable: false
                        }]}
                        rows={userQueries}
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterValues: [searchText],
                                },
                            },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                printOptions: { disableToolbarButton: true }
                            },
                        }}
                        onRowClick={handleRowClick}
                        onFilterModelChange={onFilterModelChangedCallback}
                    />
                </Box>
            </Grid>
        </Grid>
      
    </div>
  );
}