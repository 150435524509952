import CryptoJS from "crypto-js";
import {secretKey} from "./constants";

export const getSettings = async () => {
    let encryptedSettingsObject = sessionStorage.getItem("settingsObject")
    if (encryptedSettingsObject == null) {
        let baseUrl = "";
        const settingsFileAddress = process.env.NODE_ENV === "development"
            ? baseUrl + "/settings/settings.local.json"
            : baseUrl + "/settings/settings.json"
        const settingsResponse = await fetch(settingsFileAddress)
        let settingsObject = await settingsResponse.json()

        encryptedSettingsObject = CryptoJS.AES.encrypt(
            JSON.stringify(settingsObject),
            secretKey
          ).toString();
          
        sessionStorage.setItem("settingsObject", encryptedSettingsObject);  
        return settingsObject;  
    }

    const bytes = CryptoJS.AES.decrypt(encryptedSettingsObject, secretKey);
    const decryptedSettingsObject = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedSettingsObject;
}
