import { AppBar, Grid, Toolbar, Typography, ListItem, ListItemButton, ListItemText, Stack, Avatar, Menu, IconButton, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const NavigationBar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function stringAvatar(name) {
    return {
      children: name ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : '',
    };
  }

  return (
    <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <Toolbar>
          <Grid container >
            <Grid item xs={6}>
                <Box sx={{marginTop:'15px'}}>
                  <Typography sx={{color: 'primary.contrastText'}}> 
                    M&G Investment Data Hub
                  </Typography>
                </Box>
            </Grid>
            <Grid item xs>
              <Box sx={{alignItems:'center', display:'flex', justifyContent:'right'}}>

                {props.user != null
                ? <Typography sx={{color: 'primary.contrastText'}}>{props.user.displayName}</Typography>
                : <Typography sx={{color: 'primary.contrastText'}}>Loading...</Typography>
                }

                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Stack direction="row" spacing={2}>
                      <Avatar sx= {{bgcolor: 'primary.lightGreen'}} {...stringAvatar(props.user?.displayName)}/>
                    </Stack>
                    
                  </IconButton>
                </Tooltip>
                <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 100,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <Link to='/manageLists' style={{ textDecoration: "none", color: "#333" }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary="Manage Lists" />
                        </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to='/manageQueries' style={{ textDecoration: "none", color: "#333" }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary="Manage Queries" />
                        </ListItemButton>
                    </ListItem>
                  </Link>
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
  )
}

export default NavigationBar