import { Delete } from '@mui/icons-material';
import { Autocomplete, Grid, IconButton, Paper, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

const OrderByFilter = ({ selectedItem, setSelectedItem, deleteOrderBy }) => {
    const orderOptions = ['ASC', 'DESC'];
    const [columnValue, setColumnValue] = useState(null);
    const [directionValue, setDirectionValue] = useState(null);
    const [columnLoading, setColumnLoading] = useState(true);

    const deleteOrder = () => {
        deleteOrderBy();
    }

    useEffect(() => {

        setColumnValue(selectedItem.orderByColumn)
    }, [selectedItem.orderByColumn])

    useEffect(() => {
        setDirectionValue(selectedItem.orderByDirection)
    }, [selectedItem.orderByDirection])

    return (
        <Grid item container xs={12}>
            <Paper variant="outlined" sx={{ padding: '10px', width:'15%'}}>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={selectedItem.orderColumns}
                            value={columnValue}
                            renderInput={(params) => <TextField {...params} size="small" label="Order By Column" />}
                            onChange={(event) =>
                                setSelectedItem(prevState => {
                                    let newValue = event.target.innerText ? event.target.innerText : null;
                                    let newObj = {
                                        ...prevState, input: {
                                            ...prevState.input, headers: {
                                                ...prevState.input.headers, orderByColumn: newValue , orderByDirection: prevState.orderByDirection
                                            }
                                        }
                                    }
                                    newObj.orderByColumn = newValue;
                                    setColumnValue(newValue)
                                    return newObj
                                })
                            }
                            loading={columnLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={orderOptions}
                            value={directionValue}
                            defaultValue={directionValue}
                            renderInput={(params) => <TextField {...params} size="small" label="Order By Direction" />}
                            onChange={(event) =>
                                setSelectedItem(prevState => {
                                    let newValue = event.target.innerText ? event.target.innerText : null;
                                    let newObj = {
                                        ...prevState, input: {
                                            ...prevState.input, headers: {
                                                ...prevState.input.headers, orderByDirection: newValue, orderByColumn: prevState.orderByColumn
                                            }
                                        }
                                    }
                                    newObj.orderByDirection = newValue;
                                    setDirectionValue(newValue)
                                    return newObj
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton onClick={() => deleteOrder()}>
                            <Delete />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default React.memo(OrderByFilter)