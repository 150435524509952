import { Box, Button, ButtonGroup, CircularProgress, Divider, Grid, List, ListItem, ListItemText, Typography, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import doRequest from '../services/apiRequestor';
import { fetchBespokeDefaultFilters, formatCustomParametersIntoFormData, fetchAvailableDates } from '../services/customQueryService';
import CustomViewParameter from './CustomViewParameter';
import { Save, ClearAll } from '@mui/icons-material';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SaveQueryDialog from './SaveQueryDialog';
import SearchQueryDialog from './SearchQueryDialog';
import OverwriteQueryDialog from './OverwriteQueryDialog';
import { downloadFile } from '../services/customQueryService'
import { getSettings } from '../../lib/services/settings'

const CustomViewItem = ({ user, view, idToken, setDataPreview, setErrorMessage, updateStausMessage, setMessage, setClassName, setShowBanner }) => {
    const enabled = view.enabled
    const [downloadUrl, setDownloadUrl] = useState(`/api/CustomViewData/?CustomViewName=${view.name}`);
    const [customParameterValues, setCustomParameterValues] = useState({});
    const [formData, setFormData] = useState({});
    const [triggerClearFilters, setTriggerClearFilters] = useState(false);
    const [triggerDataPreview, setTriggerDataPreview] = useState(0)
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState(null);
    const [open, setOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openOverwriteQueryDialog, setOpenOverwriteQueryDialog] = useState(false);
    const [selectedQueryObj, setSelectedQueryObj] = useState(null);
    const [reloadSelectedQuery, setReloadSelectedQuery] = useState(false);
    const [availableDates, setAvailableDates] = useState([]);
    const [validationState, setvalidationState] = useState({});
    const containerName = "custom-product-reports";

    const poll = async function (fn, data, fnCondition, ms) {
        let result = await fn(data);
        console.log(result)
        while (fnCondition(result)) {
            console.log("Report still running, retrying later...")
            await wait(ms);
            result = await fn(data);
        }
        console.log("Report finished running.")
        return result;
    };

    const wait = function (ms = 1000) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    };

    let fetchData = async (data) => {
        let fetchResult;
        await doRequest(`/api/IsDownloadComplete`, idToken, {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((response) => {
                console.log(response.status);
                fetchResult = response;
            })
            .catch((e) => {
                setLoading(false);
                setShowBanner(false);
                let error = JSON.parse(e.message);
                setDataPreview("error")
                setErrorMessage(error.body);
            })
        return fetchResult;
    };
    async function downloadData() {
        if (!validateState(view.customViewsParameters, formData)) {
            setLoading(false);
            return;
        }

        console.log("Downloading custom product report...");
        setMessage('Downloading report...Please do not refresh or close the window.')
        setClassName("banner banner-warning");
        setShowBanner(true);
        setDataPreview(null);
        setErrorMessage(null);
        let validate = response => response.status === 202;

        const path = downloadUrl;
        const settings = await getSettings();
        const pathConstruct = !path.includes('http') ? `${settings.baseUrl}${path}` : path;
        let downloadResponse = null;
        await doRequest(pathConstruct, idToken, {
            method: "POST",
            body: JSON.stringify(formData)
        })
            .then(async (response) => {
                if (!response.isError) {
                    downloadResponse = response;
                }
                else {
                    setLoading(false);
                    setMethod(null);
                    setShowBanner(false);
                    setDataPreview("error")
                    setErrorMessage(response.data);
                }
            })
            .catch((e) => {
                setLoading(false);
                setMethod(null);
                setShowBanner(false);
                let error = JSON.parse(e.message);
                setDataPreview("error")
                setErrorMessage(error.body);
            })
        const pollingInterval = 30000;

        if (!downloadResponse) {
            setLoading(false);
            setShowBanner(false);
            return;
        }

        let isDownloadReadyResponse = await poll(fetchData, downloadResponse.data, validate, pollingInterval);
        console.log(isDownloadReadyResponse);
        if (isDownloadReadyResponse.ok) {
            const jsonData = isDownloadReadyResponse.data;
            const downloadUri = `/api/DownloadReport/?containerName=${containerName}&&fileName=${jsonData.output}`;
            const downloadPath = !downloadUri.includes('http') ? `${settings.baseUrl}${downloadUri}` : downloadUri;
            if (jsonData.output) {
                processDownloading(downloadPath, jsonData.output);
                setTimeout(() => {
                    setLoading(false);
                    setShowBanner(false);
                }, 5000);
            }
            else {
                setLoading(false);
                setShowBanner(false);
                setDataPreview("error")
                setErrorMessage("Missing file to download.");
            }
        }
        else {
            setLoading(false);
            setShowBanner(false);
            const error = isDownloadReadyResponse.data;
            setDataPreview("error")
            setErrorMessage(error);
        }
    }

    let processDownloading = async (path, fileName) => {
        const response = await doRequest(path, idToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
        if (!response.isError) {
            var file = { content: response.data, fileName: removeGuidFromFilename(fileName) };
            downloadFile(file);
        } else {
            setLoading(false);
            setShowBanner(false);
            setDataPreview("error")
            setErrorMessage('Failed to download file:', response.statusText);
        }
    }

    const removeGuidFromFilename = (filename) => {
        let guidPattern = /_[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}\.csv$/;
        return filename.replace(guidPattern, '.csv');
    };

    useEffect(() => {
        formatCustomParametersIntoFormData(customParameterValues, view.customViewsParameters, setFormData)
    }, [customParameterValues])

    useEffect(() => {
        if (view.customViewsParameters && view.customViewsParameters.length > 0) {
            view.customViewsParameters.map((parameter) => {
                setvalidationState(prevState => {
                    let updatedState = { ...prevState, [parameter.name]: {error: false} };
                    return updatedState;
                });
            })

            fetchBespokeDefaultFilters(idToken, view, user, customParameterValues, setCustomParameterValues);
            var dateFilterExists = view.customViewsParameters.filter(p => p.parameterType === "DateTime");
            if (dateFilterExists && dateFilterExists.length > 0) {
                fetchAvailableDates(idToken, view.dataProductsForAvailableDates, setAvailableDates);
            }
        }
        setDownloadUrl(`/api/CustomViewData/?CustomViewName=${view.name}`)
    }, [view.name])

    useEffect(() => {
        if (triggerDataPreview === 0) return
        formatCustomParametersIntoFormData(customParameterValues, view.customViewsParameters, setFormData)
        fetchDataPreview()
    }, [triggerDataPreview])

    const validateState = (customViewsParameters, formData) => {
        let isValid = true;
        if (customViewsParameters && customViewsParameters.length > 0) {
            customViewsParameters.map((parameter) => {
                if (parameter.required){
                    if(formData[parameter.name] === undefined || formData[parameter.name] === null || formData[parameter.name] === "" || formData[parameter.name].length === 0){
                        if (isValid === true) isValid = false;
                        setvalidationState(prevState => {
                            let updatedState = { ...prevState, [parameter.name]: {error: true} };
                            return updatedState;
                        });
                    }
                    else if (formData[parameter.name].length > 0){
                        setvalidationState(prevState => {
                            let updatedState = { ...prevState, [parameter.name]: {error: false} };
                            return updatedState;
                        });
                    }
                }
            })
            return isValid;
        }
    }

    const fetchDataPreview = () => {
        if (!validateState(view.customViewsParameters, formData)) {
            setLoading(false);
            return;
        }

        var dataPreviewUrl = `/api/CustomViewPreview/?CustomViewName=${view.name}`
        doRequest(dataPreviewUrl, idToken, {
            method: "POST",
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if (!response.isError) {
                    setDataPreview(response)
                    setErrorMessage(null);
                    setLoading(false);
                    setMethod(null);
                }
                else {
                    setDataPreview("error")
                    setErrorMessage(response.data);
                    setLoading(false);
                    setMethod(null);
                }
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setDataPreview("error")
                setErrorMessage(error.body);
                setLoading(false);
                setMethod(null);
            })

    }

    const handleClearFilterClick = (event) => {
        setTriggerClearFilters(!triggerClearFilters);
        setSelectedQueryObj(null);
    }

    const handleClickOpen = (popupName) => {
        switch (popupName) {
            case 'SaveQueryDialog':
                if (selectedQueryObj !== null && selectedQueryObj.id && selectedQueryObj.id !== ""){
                    setOpenOverwriteQueryDialog(true);
                }
                else{
                    setOpen(true);
                }
                return
            case 'SearchQueryDialog':
                setOpenSearch(true);
                return
            default:
                return
        }
    }

    return (
        enabled &&
        <Grid item xs={12} style={{ marginTop: "10px", marginLeft: "10px" }}>
            <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ marginTop: "10px", fontWeight: 'bold', marginBottom: "10px" }}>
                        {view.displayName}
                    </Typography>
                    <Divider flexItem />

                </Grid>
                <Grid item xs={12} xl={5}>
                    <List dense={true}>
                        <ListItem>
                            <ListItemText primary="Description" secondary={view.description}></ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Recommended Usage" secondary={view.recommendedUsage}></ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Data Products Included" secondary={view.dataProducts} sx={{ overflowWrap: "break-word" }}></ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Requested By" secondary={view.requestedBy}></ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', xl: 'block' } }} />
                <Grid item xs={6} xl={4}>
                    <Typography sx={{ marginTop: "15px" }} variant="subtitle2">Parameters</Typography>
                    {
                        view.customViewsParameters.length === 0 ? <p></p> :
                            <Grid
                                sx={{ marginBottom: "10px", display: 'flex', justifyContent: 'flex-end' }}>
                                <Tooltip
                                    title={"Save Query"}>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleClickOpen("SaveQueryDialog")}>
                                        <Save />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title={"Search Query"}>
                                    <Button
                                        variant="contained"
                                        style={{ marginLeft: "10px" }}
                                        onClick={() => handleClickOpen("SearchQueryDialog")}>
                                        <SavedSearchIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title={"Clear Filters"}>
                                    <Button
                                        variant="outlined"
                                        style={{ marginLeft: "10px" }}
                                        onClick={handleClearFilterClick}>
                                        <ClearAll />
                                    </Button>
                                </Tooltip>
                                <SaveQueryDialog
                                    username={user.mail}
                                    idToken={idToken}
                                    open={open}
                                    setOpen={setOpen}
                                    productName={view.name}
                                    productType={"BespokeView"}
                                    customParameterValues={customParameterValues}
                                    updateStausMessage={updateStausMessage}
                                    setSelectedQueryObj={setSelectedQueryObj}
                                    setReloadSelectedQuery={setReloadSelectedQuery} />
                                <SearchQueryDialog
                                    userEmail={user.mail}
                                    idToken={idToken}
                                    open={openSearch}
                                    setOpen={setOpenSearch}
                                    productName={view.name}
                                    customParameterValues={customParameterValues}
                                    selectedQueryObj={selectedQueryObj}
                                    setSelectedQueryObj={setSelectedQueryObj}
                                    reloadSelectedQuery={reloadSelectedQuery}
                                    setReloadSelectedQuery={setReloadSelectedQuery}/>
                                <OverwriteQueryDialog
                                    idToken={idToken}
                                    openOverwriteQueryDialog={openOverwriteQueryDialog}
                                    setOpenOverwriteQueryDialog={setOpenOverwriteQueryDialog}
                                    selectedQueryObj={selectedQueryObj}
                                    setSelectedQueryObj={setSelectedQueryObj}
                                    customParameterValues={customParameterValues}
                                    setOpen={setOpen}
                                    updateStausMessage={updateStausMessage}
                                    setReloadSelectedQuery={setReloadSelectedQuery}
                                    setIsLoading={setLoading}/>
                            </Grid>
                    }
                    {
                        view.customViewsParameters.length === 0
                            ?
                            <Typography variant="subtitle2" sx={{ color: "#666666" }}>No parameters for this custom dataset.</Typography>
                            :
                            view.customViewsParameters.map((parameter) =>
                                <CustomViewParameter
                                    user={user}
                                    idToken={idToken}
                                    parameter={parameter}
                                    view={view}
                                    setCustomParameterValues={setCustomParameterValues}
                                    customParameterValues={customParameterValues}
                                    triggerClearFilters={triggerClearFilters}
                                    isManageQuery={false}
                                    updateStausMessage={updateStausMessage}
                                    formData={formData}
                                    productType={"BespokeView"}
                                    availableDates={availableDates}
                                    validationState={validationState}
                                />
                            )
                    }
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', xl: 'block' } }} />
                <Grid item xs>
                    <Typography sx={{ marginTop: "15px" }} variant="subtitle2">Options</Typography>
                    <Box
                        display="flex"
                        sx={{ justifyContent: { xs: "left", xl: "center" }, alignItems: { xs: "left", xl: "center" } }}
                        justifyContent="center"
                        alignItems="center">
                        <ButtonGroup orientation="vertical">
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setTriggerDataPreview(triggerDataPreview + 1);
                                    setLoading(true);
                                    setMethod("Preview")
                                }}>
                                Preview {loading === true && method === "Preview" && (
                                    <CircularProgress size={20} sx={{ marginLeft: "10px" }} />
                                )}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setLoading(true);
                                    setMethod("Download");
                                    downloadData()
                                }}>
                                Download {loading === true && method === "Download" && (
                                    <CircularProgress color="inherit" size={20} sx={{ marginLeft: "10px" }} />
                                )}
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ marginTop: { xs: "7px", xl: "0" } }} />
        </Grid>
    )
}

export default CustomViewItem