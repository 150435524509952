import doRequest from "./apiRequestor"

export const fetchCustomReportsParameters = (idToken, value, setCustomParameters) => {
    doRequest(`/api/CustomReportsParameters/?ReportName=${value}`, idToken)
        .then((response) => {
            if (!response.isError) {
                response.data ? setCustomParameters(response.data) : setCustomParameters(null)
            }
            else{
                setCustomParameters(null)
            }
        })
        .catch((error) => {
            setCustomParameters(null)
            JSON.stringify(error)
        })
}

export const formatCustomParametersIntoUrl = (customParameterValues, setCustomParameterUrl) => {
    if (customParameterValues == undefined) return
    else {
        var st = ``
        for (let i = 0; i < Object.keys(customParameterValues).length; i++) {
            var paramName = Object.keys(customParameterValues)[i]
            var selectedVariables = customParameterValues[paramName].toString();
            if (selectedVariables != "" && selectedVariables !== 'null') {
                st += `&${paramName}=${encodeURIComponent(selectedVariables)}`       
            }
        }
        setCustomParameterUrl(st)
        return st
    }
}

export const clearCustomParameterVlaues = (customParameterValues, setCustomParameterValues) => {
    if (customParameterValues == undefined) return
    else {
        for (let i = 0; i < Object.keys(customParameterValues).length; i++) {
            var paramName = Object.keys(customParameterValues)[i]
            var selectedVariables = customParameterValues[paramName].toString();
            if (selectedVariables != "" && selectedVariables !== 'null') {
                customParameterValues[paramName] = [];
            }
        }
        setCustomParameterValues(customParameterValues);
    }
}

export const formatCustomParamForFilterIntoUrl = (customParameterValues, fieldName) => {
    if (customParameterValues == undefined) return
    else {
        var st = ``
        for (let i = 0; i < Object.keys(customParameterValues).length; i++) {
            var paramName = Object.keys(customParameterValues)[i]
            var selectedVariables = customParameterValues[paramName].toString();
            if (selectedVariables != "" && paramName != fieldName) {
                st += `&${paramName}=${encodeURIComponent(selectedVariables)}`       
            }
        }
        return st
    }
}

export const fetchCustomReports = (idToken, setCustomReports, setLoading, setErrorMessage) => {
    doRequest('/api/CustomReports', idToken)
        .then((response) => {
            if (!response.isError) {
                setCustomReports(response.data)
                setLoading(false)
            }
            else{
                setLoading(false);
                setErrorMessage(response.data);
            }
        })
        .catch((e) => {
            let error = JSON.parse(e.message);
            setLoading(false);
            setErrorMessage(error.body);
        })
}

export const fetchCustomReportParameters = (idToken, customViewName, setParameters) => [
    doRequest(`/api/CustomReportParameters/?ReportName=${customViewName}`, idToken)
        .then((response) => {
            if (!response.isError) {
                setParameters(response.data)
            }
        })
        .catch((error) => { JSON.stringify(error)})
]

export const fetchAvailableFiltersData = async (idToken, reportId, setAvailableFiltersData, setLoading, options = {}) => {
    if (setLoading !== null) setLoading(true);

    let requestUrl = `/api/CustomReportParametersOptions/?ReportId=${reportId}`;

    for (const [key, value] of Object.entries(options)) {
        if (key !== 'reportId' && value !== null && value !== undefined) {
            requestUrl += `&${key}=${value}`;
        }
    }

    try {
        const response = await doRequest(requestUrl, idToken);
        if (!response.isError) {
            setAvailableFiltersData(response.data);
        }
    } catch (error) {
        JSON.stringify(error);
    } finally {
        if (setLoading !== null) setLoading(false);
    }
}

export const formatCustomParametersIntoFormData = (customParameterValues, customViewsParameters, orderOfLastChangedParam, ignoreTillOrderNo) => {
    var formData = {};
    if (customParameterValues === undefined) return formData;
    else {
        if (customViewsParameters && customViewsParameters.length > 0) {
            
            for (let i = 0; i < customViewsParameters.length; i++) {

                if(customViewsParameters[i].order <= ignoreTillOrderNo) continue;
                if(customViewsParameters[i].order > orderOfLastChangedParam) break;

                var paramName = customViewsParameters[i].name;
                var selectedVariables = customParameterValues[paramName]?.toString();
                if (selectedVariables !== undefined && selectedVariables !== "" && selectedVariables !== 'null') {
                    formData[paramName] = `${selectedVariables}`
                }
            }
            return formData;
        }
    }
}