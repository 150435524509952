import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Autocomplete, CircularProgress, Backdrop, Button, Typography, TextField, Grid } from '@mui/material'
import doRequest from '../services/apiRequestor';
import RequestStatusAlert from './RequestStatusAlert';
import { updateUserList, deleteUserList } from '../services/customQueryService';
import { regxCsvIgnoreWrappedInDoubleQuotes } from '../services/constants';
import CopyListDialog from './CopyListDialog';

export default function EditList({ idToken, drawerWidth, user }) {
    const { listId } = useParams();
    const location = useLocation();
    const data = location.state;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [id, setId] = useState(listId);
    const [name, setName] = useState("");
    const [selectedListValues, setSelectedListValues] = useState([]);
    const [description, setDescription] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [sharedWith, setSharedWith] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [openCopyListDialog, setOpenCopyListDialog] = useState(false);
    const [listData, setListData] = useState(false);
    

    const getUserList = (idToken, id) => {
        setIsLoading(true);

        let url = `/api/GetUserListById?id=${id}`
        doRequest(url, idToken)
            .then((response) => {
                if (!response.isError && response.data) {
                    let result = response.data.find((res) => res.role === '');
                    setListData(result);
                    setSelectedListValues(result.value);
                    setName(result.name);
                    setDescription(result.description);
                    setDisabled(data.viewOnly);
                    let arr = response.data.map(item => { return item.role !== '' ? item.username.trim() : '' })
                    setSharedWith(arr.filter(em=>em));
                }
                setIsLoading(false);
            })
            .catch((error) => { JSON.stringify(error) })
    }

    useEffect(() => {
        if (id) {
            getUserList(idToken, id)
        }
    }, [id])

    const handleUpdate = () => {
        clearStatusMessage();
        let userEmail = user?.mail
        validateAndUpdate(idToken, { Id: id, Name: name, Username: userEmail, Description: description, Value: selectedListValues })
    };

    const handleCopy = () => {
        clearStatusMessage();
        setOpenCopyListDialog(true);
    };

    const handleDelete = () => {
        if (id) {
            clearStatusMessage();
            deleteUserList(idToken, id, setIsLoading, deleteListSuccessCallBack, deleteListErrorCallBack);
        }
    };

    const handleClear = () => {
        setSelectedListValues([]);
        setStatusMessage(null);
        setSeverity(null);
    };

    const clearStatusMessage = () => {
        setStatusMessage(null);
        setSeverity(null);
    }

    const handleBackClick = (event) => {
        navigate('/manageLists', { state: { searchValue: data.search } });
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
    };

    const handletDescriptionchange = (event) => {
        setDescription(event.target.value)
    };

    const validateAndUpdate = (idToken, input) => {
        setIsLoading(true);

        if (input.Name === "") {
            setStatusMessage("List name is required.")
            setSeverity("warning")
            setIsLoading(false);
        }

        else if (input.Value.length === 0) {
            setStatusMessage("List values are required.")
            setSeverity("warning")
            setIsLoading(false);
        }

        else if (input.Name !== "" && input.Value.length > 0 && input.Username) {

            let url = `/api/GetUserListsByUser?userEmail=${input.Username}`
            doRequest(url, idToken)
                .then((response) => {
                    if (!response.isError) {
                        let data = response.data;
                        let matchingList = data.filter(item => item.name === input.Name && item.id !== input.Id)
                        let isUserListNameExists = (matchingList && matchingList.length > 0) ? true : false;

                        if (isUserListNameExists) {
                            setStatusMessage("List name already exists.")
                            setSeverity("error")
                            setIsLoading(false);
                        }
                        else {
                           let result = updateUserList(idToken, input, setIsLoading, successCallBack, errorCallBack)
                           setListData(result);
                        }
                    }
                })
                .catch((error) => { JSON.stringify(error) })
        }
    }

    function successCallBack() {
        setStatusMessage("List updated successfully.")
        setSeverity("success")
    }

    function errorCallBack() {
        setStatusMessage("An error occurred, please retry.")
        setSeverity("error")
    }

    function deleteListSuccessCallBack() {
        setStatusMessage("List deleted successfully.")
        setSeverity("success")

        setTimeout(() => {
            navigate('/manageLists', { state: { searchValue: data.search } });
        }, 2000);
    }

    function deleteListErrorCallBack() {
        setStatusMessage("An error occurred, please retry.")
        setSeverity("error")
    }

    return (
        <>
            <Backdrop sx={{ color: '#055A60', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CopyListDialog 
                idToken = {idToken} 
                openCopyListDialog = {openCopyListDialog}
                setOpenCopyListDialog = {setOpenCopyListDialog}
                data = {listData}
                userName = {user?.mail}
                setStausMessage ={setStatusMessage} 
                setSeverity = {setSeverity}
                openedfrom = {'EditList'}
                setId = {setId} />
            <Grid container spacing={2} style={{ width: 'auto', marginTop: "2px", marginLeft: drawerWidth - 15 }}>
                <Grid item xs={12}>
                    <RequestStatusAlert message={statusMessage} severity={severity} />
                </Grid>
                <Grid item xs={12} >
                    <Button onClick={handleBackClick}><u>Back to Manage Lists</u></Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Edit List</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField name="name" className='TextField-medium' onChange={handleNameChange} disabled={disabled} value={name} variant="outlined" label="List name" />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        sx={{ width: '75%' }}
                        multiple
                        freeSolo
                        clearOnBlur
                        value={selectedListValues}
                        limitTags={20}
                        options={[]}
                        disabled={disabled}
                        renderInput={(params) => <TextField {...params} label="List - (Add value then press enter key)" />}
                        onChange={(event, newValue) => {
                            setSelectedListValues(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            let newFilteredOptions = newInputValue.split(regxCsvIgnoreWrappedInDoubleQuotes);
                            if ((newInputValue.split('"').length - 1) % 2 === 0 && newFilteredOptions.length > 1) {
                                let newResult = newFilteredOptions
                                    .map(item => item.trim())
                                    .filter(x => x);

                                setSelectedListValues(currentObj => {
                                    return [...currentObj, ...newResult]
                                });
                                setInputValue("");
                            }
                            else {
                                setInputValue(newInputValue);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField name="description" multiline rows={2} className='TextField-medium' onChange={handletDescriptionchange} disabled={disabled} value={description} variant="outlined" label="Description" />
                </Grid>
                <Grid item xs={12} sx={{ display: data.viewOnly ? 'none' : 'display' }} >
                    <Button onClick={handleClear} style={{ marginRight: "10px" }} color="primary" variant="outlined">
                        Clear
                    </Button>
                    <Button onClick={handleUpdate} style={{ marginRight: "10px" }} variant="contained" color="primary">
                        Update
                    </Button>
                    <Button onClick={handleCopy} style={{ marginRight: "10px" }} variant="contained" color="primary">
                        Copy
                    </Button>
                    <Button onClick={handleDelete} style={{ marginRight: "10px" }} variant="contained" color="primary">
                        Delete List
                    </Button>
                </Grid>
                <Grid item xs={12} sx={{ display: data.viewOnly ? 'none' : 'display' }}>
                    <TextField name="sharedWith" multiline rows={2} className='TextField-medium' disabled={true} value={sharedWith} variant="outlined" label="Shared With" />
                </Grid>
            </Grid>
        </>
    );
}