import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { updateUserList } from '../services/customQueryService';

export default function OverwriteListDialog({idToken, openOverwriteListDialog, setOpenOverwriteListDialog, selectedListObj, setSelectedListObj, values, setOpen, updateStausMessage, setReloadSelectedList}) {
  const handleClose = () => {
    setOpenOverwriteListDialog(false);
  };

  const handleSave = () => {
    setOpenOverwriteListDialog(false);
    const newinput = {Id: selectedListObj.id, Name:selectedListObj.name, Username:selectedListObj.username, Description:selectedListObj.description, Value:values}
    updateUserList(idToken, newinput, successCallBack, errorCallBack)
    .then((data) => {
      setSelectedListObj(data)
    });
      
  };

  const handleSaveAs = () => {
    setOpenOverwriteListDialog(false);
    setOpen(true);
  };

  function successCallBack() {
    setReloadSelectedList(true);
    updateStausMessage("List updated successfully!", "success");
  }
  
  function errorCallBack() {
    updateStausMessage("An error occurred, please retry.", "error");
  }

  return (
    <div>
      <Dialog
        open={openOverwriteListDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"List Save / Save as new list."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to save existing list <b>{selectedListObj?.name}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>Save</Button>
          <Button variant="contained" onClick={handleSaveAs} autoFocus>Save As</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}