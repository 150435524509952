import { Alert, AlertTitle, Autocomplete, Button, Grid, Tab, Tabs, TextField, Typography, Backdrop, CircularProgress, FormGroup, FormControlLabel, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RequestStatusAlert from '../../lib/components/RequestStatusAlert';
import doRequest from '../../lib/services/apiRequestor';
import FilterParameter from '../../lib/components/FilterParameter';
import NewFilterParameter from '../../lib/components/NewFilterParameter';
import OrderByFilter from '../../lib/components/OrderByFilter';
import { v4 as uuidv4 } from 'uuid';
import { upload } from '@testing-library/user-event/dist/upload';
import { Radio, RadioGroup } from '@mui/material';
import { Box } from '@mui/system';
import { dataProductsAccessRulesFileName, customDataProductsAccessRulesFileName } from "../../lib/services/constants";
import { downloadFile } from "../../lib/services/customQueryService";

function DataProductPanel(props) {
    const { value, index, dataProducts, idToken, reRender, setRefresh, generateKey } = props;
    const [selectedItem, setSelectedItem] = useState(null);
    const [deletedInput, setDeletedInput] = useState([]);
    const [newFilter, setNewFilter] = useState(0);
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [newIds, setNewIds] = useState([]);
    const [isDataProductEnabled, setIsDataProductEnabled] = useState(false);
  
    var options = dataProducts?.map((item) => {
      return item.dataProductName
    });
    const loading = options.length === 0;
  
    const handleChange = (newValue) => {
      if (!newValue) return
      
      var selected = dataProducts.find((item) => {
        return item.dataProductName === newValue
      });
  
      selected.columns = [];
      selected.input = { headers: {}, parameters:[]};
      selected.appliedFilterCount = selected?.customParameters.length;
      selected?.customParameters.sort((a, b) => a.order - b.order)
      setSelectedItem(selected);
      setDeletedInput([]);
      setStatusMessage(null);
      setSeverity(null);
      setNewFilter(0);
      setIsDataProductEnabled(selected.enabled.toLowerCase() === "true");
  
      doRequest(`/api/Columns/?DataProductName=${selected.dataProductName}`, idToken, { method: "GET" })
      .then((response) => {
        setSelectedItem(prevState => {
          let newObj = { ...prevState}
          newObj.columns = response.data.filter(column => !selected.customParameters.map(x => x.name).includes(column));
          return newObj;
        });
      })
    }

    const handleDataProductEnabled = (event) => {
        setIsDataProductEnabled(event.target.checked);
        const target = event.target;
        const value = target.checked ? 'true' : 'false';
        const targetName = target.name;

        setSelectedItem(prevState => {
            let newObj = {
                ...prevState, input: {
                    ...prevState.input, headers: {
                        ...prevState.input.headers, [targetName]: value
                    }
                }
            }
            newObj[targetName] = value;
            return newObj;
        })
    }
  
    const validate =() =>{
      let validation = true;
  
        if (selectedItem?.input.parameters.length == 0
            && (selectedItem?.input.headers === undefined || Object.keys(selectedItem?.input.headers).length == 0)
            && Object.keys(deletedInput).length == 0) return;
    
      selectedItem?.input.parameters.map((param) => {
        if(param.isValid !== undefined && !param.isValid){
          setStatusMessage(statusMessage === "Parameter Name is required."? "Can not proceed with incomplete input data." : "Parameter Name is required.")
          setSeverity("warning")
          validation = false
        }
      })
  
      return validation
    }
  
    const handleSubmit = (event, input) => {
      event.preventDefault();
      
      if(!validate()) return
  
      setStatusMessage(null);
      setSeverity(null);
      setIsLoading(true);
  
        if ((input.headers !== undefined && Object.keys(input.headers).length > 0) || input.parameters?.length > 0) {
  
        doRequest(`/api/CustomParametersPut/?DataProductName=${selectedItem.dataProductName}`, idToken, {
          method: "PUT",
          body: JSON.stringify(input)
        })
        .then((response) => {
          if (!response.isError) {
            setStatusMessage("Saved.")
            setSeverity("success")
            setNewFilter(0);
            setRefresh(prevState => !prevState);
            setIsLoading(false);
          } else {
            setStatusMessage("An error occurred, please retry.")
            setSeverity("error")
            setIsLoading(false);
          }
        })
      }
  
      if (Object.keys(deletedInput).length) {
        let objDeletedItems = [];
        deletedInput?.map((parameter) => {
          objDeletedItems.push({"name" : parameter});
        })

        doRequest(`/api/CustomParametersDelete/?DataProductName=${selectedItem.dataProductName}`, idToken, {
          method: "DELETE",
          body: JSON.stringify(objDeletedItems)
        })
        .then((response) => {
          if (!response.isError) {
            setStatusMessage("Saved.")
            setSeverity("success")
            setNewFilter(0);
            setRefresh(prevState => !prevState);
            setIsLoading(false);
          } else {
            setStatusMessage("An error occurred, please retry.")
            setSeverity("error")
            setIsLoading(false);
          }
        })
        setDeletedInput([])
      }
    }
  
    const addNewFilter = () => {
      setNewIds(inputs => [...inputs, generateKey()])
      let newFilterCount = newFilter + 1
      setNewFilter(newFilterCount)
      setSelectedItem(prevState => {
        let selected = prevState;
        selected.appliedFilterCount = selected.customParameters.length + newFilterCount;
        return selected;
      })
    }
  
    const deleteNewFilter = (filterName, id) => {
      let newFilterCount = newFilter - 1;
      setSelectedItem(prevState => {
        let newObj = prevState;
  
        //adjust order of filter ahead of this filter
        let currentparameter = newObj.input.parameters.filter((item) => item.name === filterName)
        let orderNo = currentparameter[0].order
        
        newObj.input.parameters  = newObj.input.parameters.map((param) => {
          if(param.order > orderNo){
            let newOrder = param.order;
            return {...param, order: newOrder -1}
          }
          return param;
        })
  
        newObj.input.parameters = newObj.input.parameters.filter(param => param.name !== filterName)
        newObj.appliedFilterCount = newObj.customParameters.length + newFilterCount;
  
        return newObj;
      })
  
      setNewIds(prevState => {return prevState.filter(x=> x != id)});
      setNewFilter(newFilterCount);
    }
  
    const deleteExistingFilter = (filterName) => {
      setDeletedInput(inputs => [...inputs, filterName]); 
      let updatedItems = [];
      let isOrderAdjusted = false;
      setSelectedItem(prevState => {
        let selectedItemObj = prevState;
  
        //adjust existing filters order
        let currentparameter = selectedItemObj.customParameters.filter((item) => item.name === filterName)
        let orderNo = currentparameter[0]?.order
        let updatedCustomParameters = selectedItemObj.customParameters.map((item) => {
          if (item.order > orderNo) {
            return {...item, order: item.order - 1}
          }
          return item
        })
  
        updatedCustomParameters.map((item) => {
          if (item.order >= orderNo && updatedItems.filter((x) => x.name === item.name).length === 0 ) {
            updatedItems.push({name: item.name, order: item.order})
          }
        })
        selectedItemObj.customParameters = updatedCustomParameters.filter((item) => item.name !== filterName);
  
        //adjust and update new/modified filters order
        if(!isOrderAdjusted){
          selectedItemObj.input.parameters = selectedItemObj.input.parameters.map((param) => {
            if(param.order > orderNo){
              isOrderAdjusted = true;
              return {...param, order : param.order - 1};
            }
            return param;
          })
        }
  
        updatedItems.map((item) =>{
          let isModificationConsidered = selectedItemObj.input.parameters.filter((param) => param.name === item.name)
          if(isModificationConsidered.length === 0){
            selectedItemObj.input.parameters = [...selectedItemObj.input.parameters, item]
          }
        })
  
        //remove modification entry
        selectedItemObj.input.parameters = selectedItemObj.input.parameters.filter((param) => {return param.name !== filterName})
        selectedItemObj.appliedFilterCount = selectedItemObj.customParameters.length + newFilter;
        return selectedItemObj;
      });
    }
  
    useEffect(() => {
        setSelectedItem(null);
        setDeletedInput([]);
        setStatusMessage(null);
        setSeverity(null);
    }, [value])
  
    useEffect(() => {
      if (selectedItem && statusMessage) {
        handleChange(selectedItem.dataProductName)
      }
    },[reRender])
  
    return (
      <Grid container item xs={12} hidden={value !== index} spacing={1}>
        {value === index && (
          <>
            <Grid item xs={10}>
              <Autocomplete
                options={options}
                renderInput={(params) => <TextField {...params} label="Data Product" />}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                loading={loading}
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" size="large" onClick={(event) => handleSubmit(event, selectedItem.input)} sx={{ marginTop: "7px"}}>Save</Button>
              <Backdrop sx={{ color: '#055A60', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
            <Grid item xs={12}>
              <RequestStatusAlert message={statusMessage} severity={severity} />
            </Grid>
            
            {selectedItem && (
              <Grid container spacing={2} style={{ width: '100%', marginTop: '5px', marginLeft: '5px' }}>
                <Grid
                    item xs={5}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={isDataProductEnabled} onChange={(event) => handleDataProductEnabled(event)} />}
                            label="Active"
                            name="enabled"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Custom Filters</Typography>
                </Grid>
                <Grid item xs={12} style={{marginBottom: '10px'}}>
                  <Button variant="contained" onClick={addNewFilter}>Add Filter</Button>
                </Grid>
                <Grid item xs={12}>
                <form>
                <Grid container spacing={2} sx={{ width: '100%'}}>
                  { 
                  selectedItem?.customParameters?.map((parameter, index) => {
                      return (
                        <FilterParameter key={parameter.id} index={index} parameterName={parameter.name} selectedItem={selectedItem} setSelectedItem={setSelectedItem} deleteFilter={deleteExistingFilter} type={"dataProduct"}/>
                      )
                    })}
                  { [...Array(newFilter).keys()].map((index) => {                    
                      return (
                        <NewFilterParameter key={newIds[index]} id={newIds[index]} index={index} selectedItem={selectedItem} setSelectedItem={setSelectedItem} deleteFilter={deleteNewFilter} type={"dataProduct"}/>
                      )
                    })}
                </Grid>
                </form>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    )
  }
  
function BespokeViewPanel(props) {
    const { children, value, index, idToken, bespokeViews, reRender, setRefresh, generateKey, ...other } = props;
    const [selectedItem, setSelectedItem] = useState(null);
    const [deletedInput, setDeletedInput] = useState([]);
    const [newFilter, setNewFilter] = useState(0);
    const [newIds, setNewIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [disableOrderBtn, setDisableOrderBtn] = useState(false);
    const [showOrderPanel, setShowOrderPanel] = useState(false);
    const [isBespokeEnabled, setIsBespokeEnabled] = useState(false);

    var options = bespokeViews?.map((item) => {
        return item.displayName
    });
    const loading = options.length === 0;

    const handleChange = (newValue) => {
        if (!newValue) return

        var selected = bespokeViews.find((item) => {
            return item.displayName === newValue
        });

        let appliedFilterCount = selected?.customViewsParameters.length;

        selected.columns = [];
        selected.orderColumns = [];
        selected.input = { headers: {}, parameters: [] };
        selected.appliedFilterCount = appliedFilterCount;
        selected?.customViewsParameters.sort((a, b) => a.order - b.order)
        setSelectedItem(selected);
        setDeletedInput([]);
        setStatusMessage(null);
        setSeverity(null);
        setNewFilter(0);
        setDisableOrderBtn(selected.orderByColumn ? true : false);
        setShowOrderPanel(selected.orderByColumn ? true : false);
        setIsBespokeEnabled(selected.enabled.toLowerCase() === "true");

        doRequest(`/api/Columns/?CustomViewName=${selected.name}`, idToken, { method: "GET" })
            .then((response) => {
                setSelectedItem(prevState => {
                    let newObj = { ...prevState }
                    newObj.columns = response.data.filter(column => !selected.customViewsParameters.map(x => x.name).includes(column));
                    newObj.orderColumns = response.data
                    return newObj;
                });
            })
    }

    const handleBespokeEnabled = (event) => {
        setIsBespokeEnabled(event.target.checked);
        const target = event.target;
        const value = target.checked ? 'true' : 'false';
        const targetName = target.name;

        setSelectedItem(prevState => {
            let newObj = {
                ...prevState, input: {
                    ...prevState.input, headers: {
                        ...prevState.input.headers, [targetName]: value
                    }
                }
            }
            newObj[targetName] = value;
            return newObj;
        })
    }

    const handleHeaderInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const targetName = target.name;

        setSelectedItem(prevState => {
            let newObj = {
                ...prevState, input: {
                    ...prevState.input, headers: {
                        ...prevState.input.headers, [targetName]: value
                    }
                }
            }
            newObj[targetName] = value;
            return newObj;
        })
    }

    const addNewFilter = () => {
        setNewIds(inputs => [...inputs, generateKey()])
        let newFilterCount = newFilter + 1;
        setNewFilter(newFilterCount)
        setSelectedItem(prevState => {
            let selected = prevState;
            selected.appliedFilterCount = selected.customViewsParameters.length + newFilterCount;
            return selected;
        })
    }

    const addNewOrder = () => {
        setShowOrderPanel(true)
        setDisableOrderBtn(true);
    }

    const deleteOrderBy = () => {
        setSelectedItem(prevState => {
            let newObj = prevState;
            newObj.orderByColumn = null;
            newObj.orderByDirection = null;
            newObj.input.headers.orderByColumn = null
            newObj.input.headers.orderByDirection = null
            return newObj;
        })
        setShowOrderPanel(false)
        setDisableOrderBtn(false);
    }

    const deleteNewFilter = (filterName, id) => {
        let newFilterCount = newFilter - 1;
        setSelectedItem(prevState => {
            let newObj = prevState;

            //adjust order of filter ahead of this filter
            let currentparameter = newObj.input.parameters.filter((item) => item.name === filterName)
            let orderNo = currentparameter[0]?.order

            newObj.input.parameters = newObj.input.parameters.map((param) => {
                if (param.order > orderNo) {
                    let newOrder = param.order;
                    return { ...param, order: newOrder - 1 }
                }
                return param;
            })

            newObj.input.parameters = newObj.input.parameters.filter(param => param.name !== filterName)
            newObj.appliedFilterCount = newObj.customViewsParameters.length + newFilterCount;

            return newObj;
        })

        setNewIds(prevState => { return prevState.filter(x => x != id) });
        setNewFilter(newFilterCount);
    }

    const deleteExistingFilter = (filterName) => {
        setDeletedInput(inputs => [...inputs, filterName]);
        let updatedItems = [];
        let isOrderAdjusted = false;
        setSelectedItem(prevState => {

            //adjust existing filters order
            let selectedItemObj = prevState;
            let currentparameter = selectedItemObj.customViewsParameters.filter((item) => item.name === filterName)
            let orderNo = currentparameter[0]?.order
            let updatedCustomViewsParameters = selectedItemObj.customViewsParameters.map((item) => {
                if (item.order > orderNo) {
                    return { ...item, order: item.order - 1 }
                }
                return item
            })

            updatedCustomViewsParameters.map((item) => {
                if (item.order >= orderNo && updatedItems.filter((x) => x.name === item.name).length === 0) {
                    updatedItems.push({ name: item.name, order: item.order })
                }
            })
            selectedItemObj.customViewsParameters = updatedCustomViewsParameters.filter((item) => item.name !== filterName);

            //adjust and update new/modified filters order
            if (!isOrderAdjusted) {
                selectedItemObj.input.parameters = selectedItemObj.input.parameters.map((param) => {
                    if (param.order > orderNo) {
                        isOrderAdjusted = true;
                        return { ...param, order: param.order - 1 };
                    }
                    return param;
                })
            }

            updatedItems.map((item) => {
                let isModificationConsidered = selectedItemObj.input.parameters.filter((param) => param.name === item.name)
                if (isModificationConsidered.length === 0) {
                    selectedItemObj.input.parameters = [...selectedItemObj.input.parameters, item]
                }
            })

            //remove modification entry
            selectedItemObj.input.parameters = selectedItemObj.input.parameters.filter((param) => { return param.name !== filterName })
            selectedItemObj.appliedFilterCount = selectedItemObj.customViewsParameters.length + newFilter;
            return selectedItemObj;
        });
    }

    const validate = () => {
        let validation = true

        if (selectedItem?.input.parameters.length === 0 &&
            (selectedItem?.input.headers === undefined || Object.keys(selectedItem?.input.headers).length === 0) &&
            Object.keys(deletedInput).length === 0) return;

        selectedItem?.input.parameters.map((param) => {
            if (param.isValid !== undefined && !param.isValid) {
                setStatusMessage(statusMessage === "Parameter Name is required." ? "Can not proceed with in complete input data." : "Parameter Name is required.")
                setSeverity("warning")
                return validation = false
            }
        })

        if(showOrderPanel){
            if (selectedItem?.input.headers.hasOwnProperty('orderByColumn') && (selectedItem.input.headers.orderByColumn === undefined || selectedItem.input.headers.orderByColumn === null)){
                setStatusMessage(statusMessage === "Order By Column is required." ? "Can not proceed with incomplete input data." : "Order By Column is required.")
                setSeverity("warning")
                return validation = false;
            }
            if (selectedItem?.input.headers.hasOwnProperty('orderByDirection') && (selectedItem.input.headers.orderByDirection === undefined || selectedItem.input.headers.orderByDirection === null)){
                setStatusMessage(statusMessage === "Order By Direction is required." ? "Can not proceed with incomplete input data." : "Order By Direction is required.")
                setSeverity("warning")
                return validation = false;
            }
        }

        return validation
    }

    const handleSubmit = (event, input) => {
        event.preventDefault();
        if (!validate()) return

        setStatusMessage(null);
        setSeverity(null)
        setIsLoading(true);

        if ((input.headers !== undefined && Object.keys(input.headers).length > 0) || input.parameters?.length > 0) {
            doRequest(`/api/CustomViewPut/?customViewId=${selectedItem.id}`, idToken, {
                method: "PUT",
                body: JSON.stringify(input)

            })
                .then((response) => {
                    if (!response.isError) {
                        setStatusMessage("Saved.")
                        setSeverity("success")
                        setNewFilter(0);
                        setRefresh(prevState => !prevState);
                        setIsLoading(false)
                    } else {
                        setStatusMessage("An error occurred, please retry.")
                        setSeverity("error")
                        setIsLoading(false);
                    }
                });
        }

        if (Object.keys(deletedInput).length) {
          let objDeletedItems = [];
          deletedInput?.map((parameter) => {
            objDeletedItems.push({"name" : parameter});
          })

            doRequest(`/api/CustomViewParametersDelete/?customViewId=${selectedItem.id}`, idToken, {
                method: "DELETE",
                body: JSON.stringify(objDeletedItems)
            })
                .then((response) => {
                    if (!response.isError) {
                        setStatusMessage("Saved.")
                        setSeverity("success")
                        setNewFilter(0);
                        setRefresh(prevState => !prevState);
                        setIsLoading(false);
                    } else {
                        setStatusMessage("An error occurred, please retry.")
                        setSeverity("error")
                        setIsLoading(false);
                    }
                })
            setDeletedInput([])
        }
    }

    useEffect(() => {
        setSelectedItem(null)
        setDeletedInput([])
        setStatusMessage(null)
        setSeverity(null)
    }, [value])

    useEffect(() => {
        if (selectedItem && statusMessage) {
            handleChange(selectedItem.displayName)
        }
    }, [reRender])

    return (
        <Grid item xs={12} hidden={value !== index} {...other}>
            {value === index && (
                <>
                    <form>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={11}>
                                <Autocomplete
                                    options={options}
                                    renderInput={(params) => <TextField {...params} label="Bespoke View" />}
                                    onChange={(event, newValue) => { handleChange(newValue); }}
                                    loading={loading}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" size="large" onClick={(event) => handleSubmit(event, selectedItem.input)} sx={{ marginTop: "7px" }}>Save</Button>
                                <Backdrop sx={{ color: '#055A60', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </Grid>
                            <Grid item xs={12}>
                                <RequestStatusAlert message={statusMessage} severity={severity} />
                            </Grid>
                            {selectedItem && (
                                <>
                                    <Grid item container spacing={2}>
                                        <Grid item xs={9}>
                                            <TextField label="Description" size="small" fullWidth name="description" value={selectedItem.description} onChange={(event) => handleHeaderInputChange(event)} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField label="Recommended Usage" size="small" fullWidth name="recommendedUsage" value={selectedItem.recommendedUsage} onChange={(event) => handleHeaderInputChange(event)} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField label="Data Products Included" size="small" fullWidth name="dataProducts" value={selectedItem.dataProducts} onChange={(event) => handleHeaderInputChange(event)} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextField label="Requested by" size="small" fullWidth name="requestedBy" value={selectedItem.requestedBy} onChange={(event) => handleHeaderInputChange(event)} />
                                        </Grid>
                                        <Grid
                                            item xs={5}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Switch checked={isBespokeEnabled} onChange={(event) => handleBespokeEnabled(event)} />}
                                                    label="Active"
                                                    name="enabled"
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                            <Button variant="contained" onClick={addNewFilter}>Add Filter</Button>
                                            <Button variant="contained" style={{ marginLeft: '10px' }} disabled={disableOrderBtn} onClick={addNewOrder}>Add Order By</Button>
                                        </Grid>
                                        {
                                            (selectedItem?.customViewsParameters.length > 0 || [...Array(newFilter).keys()].length > 0) &&
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><b>Custom Filters</b></Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} sx={{ width: '100%' }}>
                                            {selectedItem?.customViewsParameters?.map((parameter, index) => {
                                                return (
                                                    <FilterParameter key={parameter.id} index={index} parameterName={parameter.name} selectedItem={selectedItem} setSelectedItem={setSelectedItem} deleteFilter={deleteExistingFilter} type={"bespoke"} />
                                                )
                                            })}
                                            {[...Array(newFilter).keys()].map((index) => {
                                                return (
                                                    <NewFilterParameter key={newIds[index]} id={newIds[index]} index={index} selectedItem={selectedItem} setSelectedItem={setSelectedItem} deleteFilter={deleteNewFilter} type={"bespoke"} />
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <form>
                                            {
                                                (selectedItem?.orderByColumn || showOrderPanel) &&
                                                <Grid sx={{ width: '100%' }}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6"><b>Order by</b></Typography>
                                                    </Grid>
                                                    <OrderByFilter selectedItem={selectedItem} setSelectedItem={setSelectedItem} deleteOrderBy={deleteOrderBy} />
                                                </Grid>
                                            }
                                        </form>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </form>
                </>
            )}
        </Grid>
    )
}

function AccessControlPanel(props) {
    const { value, index } = props;
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [resultMessage, setResultMessage] = useState(null);
    const [optionValue, setOptionValue] = useState(null);

    const setFileToUpload = (e) => {
        setFile(e.target.files[0]);        
    };

    const handleChange = (event) => {
      setOptionValue(event.target.value);
      console.log("handleChange called");
      console.log(event.target.value);
    };

    const validate =() =>{ 
      console.log("dataProductsAccessRulesFileName:");
      console.log(dataProductsAccessRulesFileName);
      if (optionValue === null) {
        setErrorMessage("Please select a rules file type to upload.");
        return false;
      }

      if (file === null) {
        setErrorMessage("Please select a file to upload.");
        return false;
      }
      else if (optionValue === "data-products-access-rules" && file.name !== dataProductsAccessRulesFileName) {
        setErrorMessage("Please select a valid file with name : " + dataProductsAccessRulesFileName + " to upload.");
        return false;
      }
      else if (optionValue === "custom-data-products-access-rules" && file.name !== customDataProductsAccessRulesFileName) {
        setErrorMessage("Please select a valid file with name : " + customDataProductsAccessRulesFileName + " to upload.");
        return false;
      }

      return true;
    }

    const validateDownload =() =>{ 
      console.log("dataProductsAccessRulesFileName:");
      console.log(dataProductsAccessRulesFileName);
      if (optionValue === null) {
        setErrorMessage("Please select a rules file type to Download.");
        return false;
      }

      return true;
    }

    const uploadFile = (e) => {
      setErrorMessage(null);
      setResultMessage(null);

      if (!validate()) return;

      doRequest(`/api/DataProductsPermissionRules?fileName=${file.name}`, null, {
        method: "POST",
        body: file,
        headers: {
          'content-type': file.type,
          'content-length': `${file.size}`
        }
    })
        .then((response) => {
            if (!response.isError) {
              console.log(response);
              setErrorMessage(null);
              setResultMessage("File uploaded successfully.");
            }
            else {
              console.log(response);
              setErrorMessage("File upload failed." + response.data);
            }
        })
        .catch((e) => {
          console.error(e);
            let error = JSON.parse(e.message);
            setErrorMessage(error);
        });
    };
    
    const DownloadPermissionRuleFile = (e) => {
      setErrorMessage(null);
      setResultMessage(null);

      if (!validateDownload()) return;

      var filename = `${optionValue}.json`
      doRequest(`/api/DownloadPermissionRuleFile?fileName=${filename}`, null, {
        method: "GET"
      })
        .then((response) => {
          if (!response.isError) {
            var file = { content: response.data , fileName: filename };
            downloadFile(file);
          } else {
              setErrorMessage('Failed to download file:', response.statusText);
          }
        })
        .catch((e) => {
          console.error(e);
            let error = JSON.parse(e.message);
            setErrorMessage(error);
        });
    };

    return (
      <Grid item xs={12} hidden={value !== index}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <span>Rules file type</span>
            <br/>
            <RadioGroup aria-label="options" name="options" value={optionValue} onChange={handleChange}>
              <FormControlLabel value="data-products-access-rules" control={
                <Radio sx={{ color: 'primary.main', '&.Mui-checked': { color: 'secondary.main' } }}/>
              } label="Data products access rules" />
              <FormControlLabel value="custom-data-products-access-rules" control={
                <Radio sx={{ color: 'primary.main', '&.Mui-checked': { color: 'secondary.main' } }}/>
              } label="Custom data products access rules" />
            </RadioGroup>
            <br/>
            <span>Select rules file to upload </span>
            <br/><br/>
            <input type="file" single="single" id="file" accept=".json" onChange={setFileToUpload}/> 
            <br/><br/>
            <Box display="flex" gap={2}>
              <Button variant="contained" onClick={uploadFile}>Upload File</Button>
              <Button variant="contained" onClick={DownloadPermissionRuleFile}>Download File</Button>
            </Box>
            {errorMessage != null?
                          <>
                              <Alert severity="error">
                                  <AlertTitle>Error</AlertTitle>
                                  {errorMessage}
                              </Alert>
                          </>
              :resultMessage != null?
                          <>
                              <Alert severity="info">
                                  <AlertTitle>Info</AlertTitle>
                                  {resultMessage}
                              </Alert>
                          </>
              :
              <></>
              }
          </Grid>
        </Grid>
      </Grid>
    ) 
}

const AdminPage = ({ idToken, drawerWidth }) => {
    const [tab, setTab] = useState(0);
    const [dataProducts, setDataProducts] = useState([]);
    const [bespokeViews, setBespokeViews] = useState([]);
    const [refreshDataProducts, setRefreshDataProducts] = useState(false);
    const [refreshBespokeViews, setRefreshBespokeViews] = useState(false);
    const [reRenderDataProducts, setReRenderDataProducts] = useState(false);
    const [reRenderBespokeViews, setReRenderBespokeViews] = useState(false);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    }

    useEffect(() => {
        doRequest('/api/DataProducts', idToken)
            .then((response) => {
                setDataProducts(response.data)
                setReRenderDataProducts(prevState => !prevState)
            })
    }, [refreshDataProducts])

    useEffect(() => {
        doRequest('/api/CustomViews', idToken)
            .then((response) => {
                setBespokeViews(response.data)
                setReRenderBespokeViews(prevState => !prevState)
            })
    }, [refreshBespokeViews])
    
    const generateKey = () => {
        return JSON.stringify(uuidv4())
    }

    return (
        <Grid container spacing={2} style={{ width: 'auto', marginTop: "2px", marginLeft: drawerWidth - 15 }}>
            <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab label="Data Products" />
                    <Tab label="Bespoke Views" />
                    <Tab label="Data Access Control" />
                </Tabs>
            </Grid>
            <Grid container item xs={12}>
                <DataProductPanel value={tab} index={0} idToken={idToken} dataProducts={dataProducts} reRender={reRenderDataProducts} setRefresh={setRefreshDataProducts} generateKey={generateKey}>
                    Data products
                </DataProductPanel>
                <BespokeViewPanel value={tab} index={1} idToken={idToken} bespokeViews={bespokeViews} reRender={reRenderBespokeViews} setRefresh={setRefreshBespokeViews} generateKey={generateKey}>
                    Bespoke views
                </BespokeViewPanel>
                <AccessControlPanel value={tab} index={2} idToken={idToken} generateKey={generateKey}>
                    Data Access Control
                </AccessControlPanel>
            </Grid>
        </Grid>
    )
}

export default React.memo(AdminPage)