import React from 'react';

const Banner = ({ message, className, showBanner }) => {

    return (
        <>
            {showBanner && (
                <div className={className} >
                    <p>{message}</p>
                </div>
            )}
        </>
    );
};

export default Banner;