import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const TableFilter = ({dataObject, searchFilter, setFilteredData, columnSetting, columnSettings, setColumnSettings, ...props}) => {
    let contentArray =  dataObject === null? [] : columnSetting.filter.getColumnData(columnSetting, dataObject);


    const [options, ] = React.useState(
      contentArray.map(content => {
        return {
         label: content
        }
     })
    );
 
    return (
    <Autocomplete
        freeSolo
        options={options}
        onChange={(event, option) => {
          columnSetting.filter.value = option ? option.label: null;
          setColumnSettings({...columnSettings});
        }}       
        size="small"
        renderInput={(params) => <TextField {...params} label="filter" />}
        renderOption={(props, option, state) => {
          const matches = match(`${option.label}`, state.inputValue);
          const parts = parse(`${option.label}`, matches);
  
          return (
            <li {...props}>
              {parts.map((part, index) => (
                <span key={index} style={{fontWeight: part.highlight ? 700 : 400 }} >
                  {part.text.replaceAll(' ', "\xa0")}
                </span>
               ))}
            </li>
          );
        }}        
       
    />
    );    
}
export default TableFilter;