export const textSearch = (value, filterValue) => {
    if(filterValue === null || value === null ) return false;
    let values = Array.isArray(value) ? value.flat(Infinity) : [value];
    return values.some(row => !row ? false : row.toLowerCase().includes(filterValue.toLowerCase()));
}
  
export const dataForColumn = (column, data) => {
    let result = data === null? [] : data.map(row => column.getData ? column.getData(row) : row[column.id])
    result = result.flat(Infinity);
    result = new Set(result);
    result =  [...result].filter(val => val !== null);
    return result;
}