import styled from '@emotion/styled';
import { Autocomplete, CircularProgress, Grid, TextField, Tooltip, Typography, Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { tooltipClasses } from '@mui/material/Tooltip';
import doRequest from '../services/apiRequestor';
import throttle from 'lodash.throttle';
import { formatCustomParamForFilterIntoUrl, formatCustomParametersIntoFormData, fetchExploreDefaultFilters } from '../services/customQueryService';
import { Save } from '@mui/icons-material';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SaveListDialog from './SaveListDialog';
import SearchListDialog from './SearchListDialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box } from '@mui/system'
import OverwriteListDialog from './OverwriteListDialog';
import { regxCsvIgnoreWrappedInDoubleQuotes } from '../services/constants';

export const FilterDescriptionTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const QueryParameterFilter = ({ user, dataProductName, item, setCustomParameterValues, idToken, toggleCustomParameters,
    customParameterValues, toggleClearFilters, openedFrom, isManageQuery, customParameters, formData, setFormData, updateStausMessage }) => {
    const [inputValue, setInputValue] = useState('');
    const [val, setVal] = useState([]);
    const [dateValue, setDateValue] = useState(null)
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState(`/api/CustomParametersOptions/?DataProductName=${dataProductName}&FieldName=${item.name}`);
    const originalUrl = `/api/CustomParametersOptions/?DataProductName=${dataProductName}&FieldName=${item.name}`;
    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [openOverwriteListDialog, setOpenOverwriteListDialog] = useState(false);
    const [value, setValue] = useState("");
    const [selectedListObj, setSelectedListObj] = useState(null);
    const [reloadSelectedList, setReloadSelectedList] = useState(false);
    const handleClickOpen = (popupName) => {
        switch (popupName) {
            case 'SaveDialog':
                if (selectedListObj !== null && selectedListObj.id && selectedListObj.id !== ""){
                    setOpenOverwriteListDialog(true);
                }
                else{
                    setOpen(true);
                }
                return
            case 'SearchListDialog':
                setOpenList(true);
                return
            default:
                return
        }
    };
    var multipleToggle = false;
    useEffect(() => {
        if (!isManageQuery) {
            setCustomParameterValues(currentObj => {
                let newObj = { ...currentObj, [item.name]: [...options] }
                return newObj
            })
        }
    }, [])

    useEffect(() => {
        if (customParameterValues[item.name] && item.parameterType.toLowerCase() === "stringarray") {
            setVal(customParameterValues[item.name])
        }
        if (customParameterValues[item.name] !== undefined && item.parameterType.toLowerCase() === "datetime") {
            setDateValue(customParameterValues[item.name] === [] ? null : customParameterValues[item.name])
        }
    }, [customParameterValues[item.name]])

    useEffect(() => {
        formatCustomParametersIntoFormData(customParameterValues, customParameters, setFormData)
    }, [toggleClearFilters, customParameterValues[item.name]])

    const throttled = useRef(throttle((url, newValue) => {
        setLoading(true);
        doRequest(url + `&SearchText=${newValue}`, idToken)
            .then((response) => {
                if (!response.isError) {
                    setOptions(response.data);
                    setLoading(false);
                }
            })
            .catch((error) => { JSON.stringify(error) })
    }, 2000))

    useEffect(() => {
        let newUrl = formatCustomParamForFilterIntoUrl(customParameterValues, item.name)
        if (formData !== null) setUrl(originalUrl + newUrl)
        if (inputValue !== '') throttled.current(url, inputValue)

    }, [inputValue, formData])

    if (item.parameterType.toString().toLowerCase() === "stringarray") multipleToggle = true;

    function convertDateFormat(date) {
        if (date === null) return "null"
        else return date.toLocaleDateString('sv')
    }

    const renderParameterInput = (item, toggleCustomParameters, multipleToggle, options, loading, val, setCustomParameterValues,
        inputValue, setInputValue, dateValue, setDateValue) => {
        switch (item.parameterType.toString().toLowerCase()) {
            case "datetime":
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={item.name}
                            inputFormat="dd/MM/yyyy"
                            value={dateValue}
                            onChange={(newValue) => {
                                setDateValue(newValue);
                                setCustomParameterValues(currentObj => {
                                    let newObj = { ...currentObj, [item.name]: convertDateFormat(newValue) }
                                    return newObj
                                });
                            }}
                            disabled={!toggleCustomParameters}
                            renderInput={(params) => <TextField {...params} error={false} sx={{ width: '80%' }} />}
                        />
                    </LocalizationProvider>
                )
            case "stringarray":
                return (

                    <div style={{ display: "flex" }}>
                        <FilterDescriptionTooltip
                            title={
                                <React.Fragment>
                                    <Typography>{item.name}</Typography>
                                    {item.description}
                                </React.Fragment>
                            }>
                            <Autocomplete
                                disabled={!toggleCustomParameters}
                                multiple={multipleToggle}
                                freeSolo
                                disablePortal
                                sx={{ width: '80%' }}
                                options={options}
                                value={val}
                                limitTags={3}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={item.name}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                                onChange={(event, newValue) => {
                                    setCustomParameterValues(currentObj => {
                                        let newObj = { ...currentObj, [item.name]: [...newValue] }
                                        setValue(newValue)
                                        return newObj

                                    })
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    let newFilteredOptions = newInputValue.split(regxCsvIgnoreWrappedInDoubleQuotes);
                                    if ((newInputValue.split('"').length - 1) % 2 === 0 && newFilteredOptions.length > 1){
                                        let newResult = newFilteredOptions
                                                                .map(item => item.trim())
                                                                .filter(x => x);
    
                                        setValue(currentObj => {
                                            return [...currentObj, ...newResult]
                                        });
                                        setCustomParameterValues(currentObj => ({
                                            ...currentObj,
                                            [item.name] : [...currentObj[item.name], ...newResult]
                                        }));
                                        setInputValue("");
                                    }
                                    else {
                                        setInputValue(newInputValue);
                                    }
                                }}
                            />
                        </FilterDescriptionTooltip>
                        {
                            !isManageQuery &&
                            <Box
                                sx={{ alignItems: 'center', display: 'flex', justifyContent: 'right' }}>
                                <Tooltip
                                    title="Save List">
                                    <span>
                                        <Button
                                            disabled={!toggleCustomParameters}
                                            variant="contained"
                                            disableElevation style={{ marginLeft: "10px" }}
                                            onClick={() => handleClickOpen("SaveDialog")}>
                                            <Save />
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Box>
                        }
                        <Box
                            sx={{ alignItems: 'center', display: 'flex', justifyContent: 'right' }}>
                            <Tooltip
                                title="Search List">
                                <span>
                                    <Button
                                        disabled={!toggleCustomParameters}
                                        variant="contained"
                                        disableElevation style={{ marginLeft: "10px" }}
                                        onClick={() => handleClickOpen("SearchListDialog")}>
                                        <SavedSearchIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>

                        <SearchListDialog
                            userEmail={user.mail}
                            idToken={idToken}
                            setValue={setValue}
                            open={openList}
                            setOpen={setOpenList}
                            filterName={item.name}
                            setCustomParameterValues={setCustomParameterValues}
                            selectedListObj={selectedListObj}
                            setSelectedListObj={setSelectedListObj}
                            reloadSelectedList={reloadSelectedList} 
                            setReloadSelectedList={setReloadSelectedList} />
                        {
                            !isManageQuery &&
                            <SaveListDialog
                                username={user.mail}
                                idToken={idToken}
                                value={val}
                                open={open}
                                setOpen={setOpen}
                                openedFrom={openedFrom}
                                filterName={item.name}
                                dataProductName={dataProductName}
                                setCustomParameterValues={setCustomParameterValues}
                                setSelectedListObj={setSelectedListObj}
                                setReloadSelectedList={setReloadSelectedList}
                                updateStausMessage={updateStausMessage} />
                        }
                        <OverwriteListDialog 
                            idToken={idToken} 
                            openOverwriteListDialog={openOverwriteListDialog} 
                            setOpenOverwriteListDialog={setOpenOverwriteListDialog} 
                            selectedListObj={selectedListObj}
                            setSelectedListObj={setSelectedListObj}
                            values={val}
                            setOpen={setOpen}
                            updateStausMessage={updateStausMessage}
                            setReloadSelectedList={setReloadSelectedList} 
                            />
                    </div>
                )
            default:
                return
        }
    }

    return (
        <>
            <Grid item xs={12} >
                {
                    renderParameterInput(item, toggleCustomParameters, multipleToggle, options, loading, val,
                        setCustomParameterValues, inputValue, setInputValue, dateValue, setDateValue)
                }
            </Grid>
        </>
    )
}

export default QueryParameterFilter