import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, CircularProgress, Button, Dialog, DialogContent, DialogTitle, TextField, Grid } from '@mui/material'
import doRequest from '../services/apiRequestor';

export default function SearchListDialog({ idToken, setValue, open, setOpen, userEmail, filterName, setCustomParameterValues, selectedListObj,  setSelectedListObj, reloadSelectedList, setReloadSelectedList}) {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [optionsDetails, setOptionsDetails] = useState([]);
    const [selectedList, setSelectedList] = useState(null);
    const [selectedListValues, setSelectedListValues] = useState([]);


    const handleClose = (e) => {
        e.preventDefault();
        setValue(selectedListValues)
        setCustomParameterValues(currentObj => {
            let newObj = { ...currentObj, [filterName]: selectedListValues }
            return newObj
        })
        let val = optionsDetails.filter(item => item.name === selectedList)
        setSelectedListObj(val[0])
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open === true) {
            getUserList(userEmail)
        }
    }, [open])

    useEffect(() => {
        if (reloadSelectedList && selectedListObj) {
            setReloadSelectedList(false);
            setSelectedList(selectedListObj.name);
            setSelectedListValues(selectedListObj.value)
        }
    }, [reloadSelectedList, selectedListObj])

    const getUserList = (userEmail) => {
        setLoading(true);

        let url = `/api/GetUserListsByUser?userEmail=${userEmail}`
        doRequest(url, idToken)
            .then((response) => {
                if (!response.isError) {
                    setOptionsDetails(response.data);

                    let lists = []
                    response.data.map(item => {
                        return lists.push(item.name)
                    })
                    setOptions(lists);
                }
                setLoading(false);
            })
            .catch((error) => { JSON.stringify(error) })
    }

    return (
        <div>
            <Dialog fullWidth maxWidth="md"
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Select List</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xl={12}>
                            <form>
                                <Autocomplete className='padding-top'
                                    disablePortal
                                    sx={{ width: '80%' }}
                                    options={options}
                                    value={selectedList}
                                    limitTags={3}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Search List"}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        setSelectedList(newValue)
                                        let selectedValues = []
                                        let val = optionsDetails.filter(item => item.name === newValue)
                                        if (val && val.length > 0) {
                                            selectedValues = selectedValues.concat(val[0].value)
                                        }
                                        setSelectedListValues(selectedValues)
                                    }}
                                />

                                <div className='padding-top'>
                                    <Autocomplete
                                        sx={{ width: '80%' }}
                                        multiple
                                        freeSolo
                                        clearOnBlur
                                        value={selectedListValues}
                                        limitTags={3}
                                        options={[]}
                                        renderInput={(params) => <TextField {...params} label="List" />}
                                    />
                                    {errors.list && <><br /><span className='errorMsg'>{errors.list}</span></>}
                                </div>
                                <div className='action-buttons'>
                                    <Button onClick={handleCancel} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleClose} type="submit" variant="contained" color="primary">
                                        OK
                                    </Button>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}