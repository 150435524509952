import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, TextField, Grid, Typography } from '@mui/material'
import doRequest from '../services/apiRequestor';
import { draftQuery } from '../services/customQueryService';

export default function SaveQueryDialog({ username, idToken, open, setOpen, productName, productType, customParameterValues, updateStausMessage, 
    setSelectedQueryObj, setReloadSelectedQuery }) {
    const [errors, setErrors] = useState({});
    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [statusMessage, setStatusMessage] = useState(null);
    const [severity, setSeverity] = useState(null);

    useEffect(() => {
        updateStausMessage(statusMessage, severity)
    }, [statusMessage, severity, updateStausMessage])

    const Validate = () => {
        setStatusMessage(null)
        setSeverity(null)

        let query = draftQuery(customParameterValues)

        const errors = {};
        if (!name) {
            errors.name = "Name is required";
        }
        else if (Object.keys(query).length === 0) {
            errors.name = "Query is empty, Please try with valid filters values.";
        }
        else if (name) {
            checkIfExist(idToken);
        }
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            setErrors({});
        }
    }

    const handleClose = (e) => {
        e.preventDefault();
        Validate();
    };

    const handleCancel = () => {
        setName("");
        setDescription("");
        setErrors({});
        setOpen(false);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handletDescriptionchange = (event) => {
        setDescription(event.target.value)
    };

    const checkIfExist = (idToken) => {

        let query = draftQuery(customParameterValues)

        var getUserUrl = `/api/GetUserQuery?queryName=${name}&userEmail=${username}&productName=${productName}`
        doRequest(getUserUrl, idToken)
            .then((response) => {
                if (!response?.isError) {
                    if (Object.keys(response?.data).length > 0) {
                        errors.name = "Query name is already exists."
                        setErrors(errors);
                    }
                    else {
                        saveUserQuery(idToken, { Name: name, UserName: username, Description: description, ProductName: productName, ProductType: productType, Query: JSON.stringify(query) })
                        setName("")
                        setDescription("")
                        setOpen(false);
                    }
                }
                else {
                    setStatusMessage("Failed to save filters.")
                    setSeverity("error")
                }
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setStatusMessage(error)
                setSeverity("error")
            })
    };

    const saveUserQuery = (idToken, formData) => {
        doRequest(`/api/SaveUserQuery`, idToken, {
            method: "POST",
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if(!response.isError){
                    setReloadSelectedQuery(true);
                    setSelectedQueryObj(response.data);
                    setStatusMessage("Query saved successfully.")
                    setSeverity("success")
                }
            })
            .catch((e) => {
                let error = JSON.parse(e.message);
                setStatusMessage(error)
                setSeverity("error")
            })
    };

    return (
        <div>
            <Dialog fullWidth maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Save Query</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} >
                            <Typography variant={'h7'}>Save a filter to create a query</Typography>
                        </Grid>
                        <Grid item xl={12}>
                            <form>
                                <div className='padding-top'>
                                    <TextField name="name" className='TextField' onChange={handleNameChange} value={name} variant="outlined" label="Name" />
                                    {errors.name && <><br /><span className='errorMsg'>{errors.name}</span></>}
                                </div>
                                <div className='padding-top'>
                                    <TextField name="description" multiline rows={4} className='TextField' onChange={handletDescriptionchange} value={description} variant="outlined" label="Description" />
                                </div>
                                <div className='action-buttons'>
                                    <Button onClick={handleCancel} color="primary" autoFocus>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleClose} type="submit" variant="contained" color="primary" autoFocus>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}