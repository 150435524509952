import { DataObject } from '@mui/icons-material';
import { Alert, AlertTitle, Grid, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomReportItem from '../../lib/components/CustomReportItem';
import ItemList from '../../lib/components/ItemList';
import { fetchCustomReports } from '../../lib/services/customReportsQueryService';
import Banner from '../../lib/components/Banner';

const CustomReportsPage = ({ idToken, drawerWidth, user }) => {
    const [customReports, setCustomReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedReport, setSelectedReport] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loadingListError, setLoadingListError] = useState(null);
    const [message, setMessage] = useState(null);
    const [className, setClassName] = useState(null);
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        setLoadingListError(null)
        fetchCustomReports(idToken, setCustomReports, setLoading, setLoadingListError);
    }, [])

    return (
        <Grid container style={{ width: 'auto', marginTop: "5px", marginLeft: drawerWidth - 23 }} >
            <Grid item xs={2.5} style={{ height: '91.5vh', borderRight: '1px solid #eee', width: '100%', overflow: 'auto' }} container direction="row">
                {
                    loading ?
                        <>
                            <Grid item xs={12} sx={{ backgroundColor: "white" }}>
                                <Skeleton variant="rectangular" width={"100%"} height={60} sx={{ marginTop: "2px" }} />
                                <Skeleton variant="rectangular" width={"100%"} height={60} sx={{ marginTop: "2px" }} />
                                <Skeleton variant="rectangular" width={"100%"} height={60} sx={{ marginTop: "2px" }} />
                            </Grid>
                        </>
                        :
                        customReports.length > 0 ?
                            <Grid item xs={12}>
                                <ItemList Items={customReports} setSelectedItem={setSelectedReport} setErrorMessage={setErrorMessage} setShowBanner={setShowBanner} />
                            </Grid>
                            :
                            loadingListError != null ?
                                <Grid container item xs={12} sx={{ height: '10vh' }} alignItems="center" justifyContent="center">
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        Loading list of custom reports have failed. Please refresh or contact support if this issue persists.
                                    </Alert>
                                </Grid>
                                :
                                <Grid container item xs={12} sx={{ height: '10vh' }} alignItems="center" justifyContent="center">
                                    <Alert severity="info">
                                        <AlertTitle>Information</AlertTitle>
                                        No custom reports currently enabled.
                                    </Alert>
                                    <Typography></Typography>
                                </Grid>
                }
            </Grid>
            <Grid item xs={9.5} style={{ height: '91.5vh' }}>
                {selectedReport &&
                    <CustomReportItem
                        item={selectedReport}
                        idToken={idToken}
                        setErrorMessage={setErrorMessage}
                        user={user}
                        setMessage={setMessage}
                        setClassName={setClassName}
                        setShowBanner={setShowBanner}
                    />
                }
                {errorMessage &&
                    <Grid container item xs={12} sx={{ height: '10vh' }} alignItems="center" justifyContent="center">
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {errorMessage}
                        </Alert>
                    </Grid>
                }
            </Grid>
            <Banner message={message} className={className} showBanner={showBanner} />
        </Grid>
    )
}

export default CustomReportsPage